/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
/* eslint-disable no-nested-ternary */

import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { Link, useParams } from 'react-router-dom';

import { Accordion, AccordionTab } from 'primereact/accordion';

import {
  getSummaryReportReviseById,
  getSummaryReport,
  getSummaryReportType,
} from 'apis/summaryReport';

import { checkOverdueDatetime, formatDate } from 'utils/func';

import { getGeneralInfoById } from 'apis/projectProposals.api';

import { TIMEZONE_VN } from 'constant';
import Loading from 'components/Loading';
import moment from 'moment';

export default function SummaryReportRequest() {
  // #region Data
  const { t } = useTranslation();
  const { id } = useParams();

  const { data: summaryReport, isLoading: isSummaryReportLoading } = useQuery(
    ['summaryReport', id],
    () => getSummaryReport(id)
  );

  const summaryReportData = useMemo(
    () => summaryReport?.data?.summaryReport ?? [],
    [summaryReport]
  );

  const { data: summaryReportType } = useQuery(['summaryReportType', id], () =>
    getSummaryReportType()
  );

  const isAcceptance = useMemo(
    () =>
      summaryReportType?.data?.findIndex(
        (type) =>
          type?.id === summaryReportData?.summaryReportTypeId && type?.code === 'acceptanceDocument'
      ) !== -1,
    [summaryReportType, summaryReport]
  );

  const { data: generalInfo } = useQuery(
    ['generalInfoProjectProposal', id],
    () => getGeneralInfoById(summaryReportData?.project?.projectProposalId),
    {
      enabled: !!summaryReportData?.project?.projectProposalId,
    }
  );
  const { data: summaryReportRevises, isLoading: isSummaryReportRevisesLoading } = useQuery(
    ['summaryReportRevises', id],
    () => getSummaryReportReviseById(id)
  );

  const summaryReportRevisesData = useMemo(
    () => summaryReportRevises?.data?.data ?? [],
    [summaryReportRevises]
  );

  // #region Render
  const formatHeader = (value, rowIndex) => {
    const requestType = isAcceptance
      ? t('project.viewHistory.status.acceptanceDocument')
      : t('project.viewHistory.status.midtermDocument');

    const deadline = value?.deadline;

    const date = new Date(deadline);

    // Trừ đi 1 giây
    date.setSeconds(date.getSeconds() - 1);

    let statusJsx = null;
    if (!value?.isRevise) {
      statusJsx = (
        <span className="font-medium text-green-500 text-base font-italic">
          {t('projectProposal.requestExplanation.ownerAddedExplanation')}
        </span>
      );
    } else if (value?.isRevise && checkOverdueDatetime(deadline)) {
      statusJsx = (
        <span className="font-medium text-red-500 text-base font-italic">
          {t('projectProposal.requestExplanation.overdueDeadline')}
        </span>
      );
    } else {
      statusJsx = (
        <span className="font-medium text-red-500 text-base font-italic">
          {t('project.requestExplanation.deadline')}:{' '}
          {deadline
            ? new Date(date).toLocaleString('en-GB', { timeZone: TIMEZONE_VN })
            : t('project.requestExplanation.noDeadline')}
        </span>
      );
    }

    return (
      <div className="flex flex-column text-lg font-bold gap-1">
        <span>
          {rowIndex + 1}. {requestType}
        </span>
        <div>{statusJsx} </div>
      </div>
    );
  };

  const formatComment = (value) => (
    <div className="mb-2">
      <span className="font-semibold">{t('project.requestExplanation.comment')}:</span>
      <p style={{ whiteSpace: 'pre-wrap' }}>
        {`--------------------------------------------------\n(${new Date(
          value?.createdAt
        ).toLocaleString('en-GB', { timeZone: TIMEZONE_VN })})\n${value?.comment}`}
      </p>
    </div>
  );

  const formatFeedback = (value) => {
    if (value?.feedback) {
      return (
        <div className="mb-2">
          <span className="font-semibold">{t('project.requestExplanation.feedback')}:</span>
          <p style={{ whiteSpace: 'pre-wrap' }}>
            {`--------------------------------------------------\n(${new Date(
              value?.updatedAt
            ).toLocaleString('en-GB', { timeZone: TIMEZONE_VN })})\n${value?.feedback}`}
          </p>
        </div>
      );
    }
    return null;
  };

  const formatActions = (value) => {
    const deadline = value?.deadline;

    return (
      <div>
        {deadline && !checkOverdueDatetime(deadline) && value?.isRevise && (
          <div className="flex justify-content-end gap-1">
            <div className="border-round-lg" style={{ backgroundColor: '#F59E0B' }}>
              <Link
                className="edit-project-proposal flex align-items-center justify-content-center font-bold text-white border-round m-2 h-2rem"
                to={`/project/${summaryReportData?.projectId}/submit-${
                  isAcceptance ? 'acceptance' : 'midterm'
                }-document/edit/${summaryReportData?.id}`}
                state={{ id: value?.id }}
              >
                Chỉnh sửa báo cáo
              </Link>
            </div>
          </div>
        )}
      </div>
    );
  };

  return (
    <div className="grid">
      {(isSummaryReportRevisesLoading || isSummaryReportLoading) && <Loading />}

      <div className="col-12">
        <div className="card">
          <div className="flex justify-content-between align-items-end mb-1 pb-2">
            <h5 className="p-0 m-0">
              {t('projectProposal.requestExplanation.updateInfoRequests')}
            </h5>
          </div>
          <div className="mb-2">
            <span className="p-0 m-0 text-blue-500 font-bold text-lg">
              {t('projectProposal.label')}:{' '}
            </span>
            <span className="text-blue-500 font-bold text-lg">
              {generalInfo?.data?.vietnameseName}
            </span>
          </div>
          <div className="my-4 border-double p-2">
            <p className="my-1">
              <b>{t('project.explanationGuideline.title')}:</b>
            </p>
            <p>
              <b className="underline">{t('guideline.step')} 1:</b>{' '}
              {t('project.explanationGuideline.step1')}
            </p>
            <p>
              <b className="underline">{t('guideline.step')} 2:</b>{' '}
              {t('project.explanationGuideline.step2.1')}{' '}
              <b className="text-orange-400">{t('project.explanationGuideline.step2.2')}</b>{' '}
              {t('project.explanationGuideline.step2.3')}{' '}
              <b>&quot;{t('explanationGuideline.step2.4')}&quot;</b>{' '}
              {t('project.explanationGuideline.step2.5')}
            </p>
          </div>

          {summaryReportRevisesData.length > 0 && (
            <Accordion
              multiple
              className="explanation-request-list"
              activeIndex={summaryReportRevisesData
                .map((item, index) => (item?.isRevise ? index : null))
                .filter((index) => index !== null)}
            >
              {summaryReportRevisesData.map((rowData, rowIndex) => (
                <AccordionTab key={rowData.id} header={formatHeader(rowData, rowIndex)}>
                  {/* Ghi chú */}
                  {formatComment(rowData)}

                  {formatFeedback(rowData)}

                  {formatActions(rowData)}
                </AccordionTab>
              ))}
            </Accordion>
          )}
        </div>
      </div>
    </div>
  );
}
