import { createBrowserRouter } from 'react-router-dom';

import C404 from 'components/404';
import CompleteProfilePage from 'pages/CompleteProfile';
import ForgotPasswordPage from 'pages/ForgotPassword';
import GoogleAuthCallbackPage from 'pages/GoogleAuthCallback';
import GuidelinePage from 'pages/Guideline';
import LoginPage from 'pages/Login';
import FinancialReviewDetailPage from 'pages/FinancialReviewDetail';
import FinancialReviewFormCreatePage from 'pages/FinancialReviewFormCreate';
import FinancialReviewFormEditPage from 'pages/FinancialReviewFormEdit';
import ProgramListPage from 'pages/ProgramList';
import ProjectProposalCreatePage from 'pages/ProjectProposalCreate';
import ProjectProposalDefenseViewListPage from 'pages/ProjectProposalDefenseViewList';
import ProjectProposalDetailInformationPage from 'pages/ProjectProposalDetailInfo';
import ProjectDetailInformationPage from 'pages/ProjectDetailInfo';
import ProjectProposalEditPage from 'pages/ProjectProposalEdit';
import ProjectProposalRequestPage from 'pages/ProjectProposalRequest';
import ProjectProposalListPage from 'pages/ProjectProposalList';
import ProjectListPage from 'pages/ProjectList';
import ResetPasswordPage from 'pages/ResetPassword';
import ScientificProfilePage from 'pages/ScientificProfile';
import ScientificProfileEditPage from 'pages/ScientificProfileEdit';
import SignupPage from 'pages/Signup';
import SpecializeReviewDetailM01Page from 'pages/SpecializeReviewDetailM01';
import SpecializeReviewFormCreatePage from 'pages/SpecializeReviewFormCreate';
import SpecializeReviewFormEditPage from 'pages/SpecializeReviewFormEdit';
import MidtermReviewDetailM06Page from 'pages/MidtermReviewDetailM06';
import MidtermReviewFormEditPage from 'pages/MidtermReviewFormEdit';
import MidtermReviewFormCreatePage from 'pages/MidtermReviewFormCreate';
import UpdatePasswordPage from 'pages/UpdatePassword';
import UserProfilePage from 'pages/UserProfile';
import VerifyAccountPage from 'pages/VerifyAccount';
import PublicProfilePage from 'pages/PublicProfilePage';
import AcceptanceReviewFormCreatePage from 'pages/AcceptanceReviewFormCreate';
import AcceptanceReviewFormEditPage from 'pages/AcceptanceReviewFormEdit';
import AcceptanceReviewDetailPage from 'pages/AcceptanceReviewDetail';
import AcceptanceDocumentCreatePage from 'pages/AcceptanceDocumentCreate';
import PrequalificationReviewDetailPage from 'pages/PrequalificationReviewDetail';
import PrequalificationReviewFormCreatePage from 'pages/PrequalificationReviewFormCreate';
import PrequalificationReviewFormEditPage from 'pages/PrequalificationReviewFormEdit';
import CataloguePage from 'pages/Catalogue';
import SubmitRegisterResultPage from 'pages/SubmitRegisterResult';
import NotificationPage from 'pages/Notification';
import MidtermDocumentCreatePage from 'pages/MidtermDocumentCreate';
import MidtermDocumentEditPage from 'pages/MidtermDocumentEdit';
import MidtermDocumentDetailPage from 'pages/MidtermDocumentDetail';
import AcceptanceDocumentEditPage from 'pages/AcceptanceDocumentEdit';
import PubProjectProposalListPage from 'pages/PubProjectProposalList';
import PubProjectProposalDetailPage from 'pages/PubProjectProposalDetail';
import ExtendImplementationTimeDetailPage from 'pages/ExtendImplementationTimeDetail';
import ViewFinancialAdjustmentPage from 'pages/ViewFinancialAdjustment';
import ProjectCompletedViewListPage from 'pages/ProjectCompletedList';
import LiquidationAgreementPage from 'pages/LiquidationAgreement';
import DetailCheckResultPage from 'pages/DetailCheckResult';
import SummaryReportRequestPage from 'pages/SummaryReportRequest';
import AcceptanceDocumentDetailPage from 'pages/AcceptanceDocumentDetail';

export default createBrowserRouter([
  {
    path: '/',
    element: <ProgramListPage />,
  },
  {
    path: '/login',
    element: <LoginPage />,
  },
  {
    path: '/update-password',
    element: <UpdatePasswordPage />,
  },
  {
    path: '/reset-password',
    element: <ResetPasswordPage />,
  },
  {
    path: '/forgot-password',
    element: <ForgotPasswordPage />,
  },
  {
    path: '/signup',
    element: <SignupPage />,
  },
  {
    path: '/auth/confirm',
    element: <VerifyAccountPage />,
  },
  {
    path: '/auth/google/callback',
    element: <GoogleAuthCallbackPage />,
  },
  {
    path: '/auth/complete-profile',
    element: <CompleteProfilePage />,
  },
  {
    path: '/me',
    element: <UserProfilePage />,
  },
  {
    path: '/scientific-profile',
    children: [
      {
        index: true,
        element: <ScientificProfilePage />,
      },
      {
        path: 'edit',
        element: ScientificProfileEditPage(false),
      },
      {
        path: 'editByFile',
        element: ScientificProfileEditPage(true),
      },
    ],
  },
  {
    path: '/profile/:idProfile',
    element: <PublicProfilePage />,
  },
  {
    path: '/project-proposal',
    children: [
      {
        index: true,
        element: <ProjectProposalListPage />,
      },
      {
        path: 'create',
        element: <ProjectProposalCreatePage />,
      },
      {
        path: 'edit/:id',
        element: <ProjectProposalEditPage />,
      },
      {
        path: 'edit/:id/*',
        element: <ProjectProposalEditPage />,
      },
      {
        path: 'request/:id',
        element: <ProjectProposalRequestPage />,
      },
      {
        path: ':id',
        children: [
          {
            index: true,
            element: <ProjectProposalDetailInformationPage />,
          },
        ],
      },
    ],
  },
  {
    path: '/pub-project-proposal',
    children: [
      {
        index: true,
        element: <PubProjectProposalListPage />,
      },
      {
        path: ':projectProposalId',
        element: <PubProjectProposalDetailPage />,
      },
    ],
  },
  {
    path: '/project',
    children: [
      {
        index: true,
        element: <ProjectListPage />,
      },
      {
        path: 'create',
        element: <ProjectProposalCreatePage />,
      },
      {
        path: 'edit/:id',
        element: <ProjectProposalEditPage />,
      },
      {
        path: 'edit/:id/*',
        element: <ProjectProposalEditPage />,
      },
      {
        path: 'request/:id',
        element: <SummaryReportRequestPage />,
      },
      {
        path: ':projectId',
        children: [
          {
            index: true,
            element: <ProjectDetailInformationPage />,
          },
          {
            path: 'submit-midterm-document',
            children: [
              {
                path: 'create',
                element: <MidtermDocumentCreatePage />,
              },
              {
                path: 'edit/:summaryReportId',
                element: <MidtermDocumentEditPage />,
              },
              {
                path: 'detail/:summaryReportId',
                element: <MidtermDocumentDetailPage />,
              },
            ],
          },
          {
            path: 'submit-acceptance-document',
            children: [
              {
                path: 'create',
                element: <AcceptanceDocumentCreatePage />,
              },
              {
                path: 'edit/:summaryReportId',
                element: <AcceptanceDocumentEditPage />,
              },
              {
                path: 'detail/:summaryReportId',
                element: <AcceptanceDocumentDetailPage />,
              },
            ],
          },
          {
            path: 'submit-register-result',
            element: <SubmitRegisterResultPage />,
          },
          {
            path: 'detail/extend-implementation-time/:id',
            element: <ExtendImplementationTimeDetailPage />,
          },
          {
            path: 'financial-adjustment/view/:financialAdjustmentId',
            element: <ViewFinancialAdjustmentPage readOnly />,
          },
          {
            path: 'detail/liquidation',
            element: <LiquidationAgreementPage />,
          },
          {
            path: 'detail/inspection-register-result',
            element: <DetailCheckResultPage />,
          },
        ],
      },
    ],
  },
  {
    path: '/project-completed',
    children: [
      {
        index: true,
        element: <ProjectCompletedViewListPage />,
      },
    ],
  },
  {
    path: '/project-proposal-defense',
    children: [
      {
        index: true,
        element: <ProjectProposalDefenseViewListPage />,
      },
    ],
  },

  {
    path: 'prequalification-review',
    children: [
      {
        path: 'detail/:projectProposalId',
        element: <PrequalificationReviewDetailPage />,
      },
      {
        path: 'create/:projectProposalId',
        element: <PrequalificationReviewFormCreatePage />,
      },
      {
        path: 'edit/:projectProposalId',
        element: <PrequalificationReviewFormEditPage />,
      },
    ],
  },
  {
    path: 'specialize-review',
    children: [
      {
        path: 'detail/:projectProposalId',
        element: <SpecializeReviewDetailM01Page />,
      },
      {
        path: 'create/:projectProposalId',
        element: <SpecializeReviewFormCreatePage />,
      },
      {
        path: 'edit/:projectProposalId',
        element: <SpecializeReviewFormEditPage />,
      },
    ],
  },
  {
    path: 'financial-review',
    children: [
      {
        path: 'detail/:projectProposalId',
        element: <FinancialReviewDetailPage />,
      },
      {
        path: 'create/:projectProposalId',
        element: <FinancialReviewFormCreatePage />,
      },
      {
        path: 'edit/:projectProposalId',
        element: <FinancialReviewFormEditPage />,
      },
    ],
  },
  {
    path: 'midterm-review',
    children: [
      {
        path: 'create/:projectProposalId',
        element: <MidtermReviewFormCreatePage />,
      },
      {
        path: 'edit/:projectProposalId',
        element: <MidtermReviewFormEditPage />,
      },
      {
        path: 'detail/:projectProposalId',
        element: <MidtermReviewDetailM06Page />,
      },
    ],
  },
  {
    path: 'acceptance-review',
    children: [
      {
        path: 'detail/:projectProposalId',
        element: <AcceptanceReviewDetailPage />,
      },
      {
        path: 'create/:projectProposalId',
        element: <AcceptanceReviewFormCreatePage />,
      },
      {
        path: 'edit/:projectProposalId',
        element: <AcceptanceReviewFormEditPage />,
      },
    ],
  },
  {
    path: 'guideline',
    element: <GuidelinePage />,
  },
  {
    path: 'forms',
    element: <CataloguePage />,
  },
  {
    path: 'notifications',
    element: <NotificationPage />,
  },
  { path: '*', element: <C404 /> },
]);
