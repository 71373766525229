// import { Column } from 'primereact/column';
// import { DataTable } from 'primereact/datatable';
import './style.scss';

import { Dialog } from 'primereact/dialog';
import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Button } from 'primereact/button';
import { Badge } from 'primereact/badge';

function MidtermDocumentDialog({ midtermDocuments, visible, setVisible }) {
  const { t } = useTranslation();
  const formatName = (value) => (
    <div>
      <Link
        to={`/project/${midtermDocuments?.[0]?.projectId}/submit-midterm-document/detail/${value?.id}`}
      >
        {t('project.viewList.midtermDocumentDialog.versionText')} {value?.version}
      </Link>
    </div>
  );

  const formatSubmitDate = (value) => (
    <div>
      {value?.submittedAt && new Date(value?.submittedAt).toLocaleDateString()}
      {!value?.submittedAt && t('project.viewList.midtermDocumentDialog.everSubmit')}
    </div>
  );

  const formatCouncilResult = (value) => {
    const isRevise =
      value?.summaryReportRevises.findIndex((item) => item?.isRevise === true) !== -1;
    const qualified = value?.council?.projectProposalCouncils?.[0]?.councilStatus;
    let result;
    if (isRevise) {
      result = t('project.viewList.midtermDocumentDialog.councilResult.revise');
    } else if (qualified) {
      result = t(`project.viewList.midtermDocumentDialog.councilResult.${qualified}`);
    } else {
      result = t('project.viewList.midtermDocumentDialog.councilResult.everCouncilResult');
    }
    return <div>{result}</div>;
  };

  const formatAction = (value) => (
    <div className="flex align-items-center justify-content-center flex-wrap border-round-lg gap-2">
      {value?.summaryReportRevises?.length === 0 && !value?.submittedAt && (
        <Link
          to={`/project/${midtermDocuments?.[0]?.projectId}/submit-midterm-document/edit/${value?.id}`}
        >
          <Button
            severity="warning"
            className="flex align-items-center justify-content-center"
            tooltip={t('projectProposal.viewList.edit')}
            tooltipOptions={{ position: 'left', at: 'left top' }}
            style={{ width: '42px', height: '42px' }}
          >
            <i className="pi pi-pencil p-overlay-badge" />
          </Button>
        </Link>
      )}

      <Link to={`/project/request/${value?.id}`}>
        <Button
          severity="help"
          className="flex align-items-center justify-content-center"
          tooltip={t('project.requestExplanation.requestRevise')}
          tooltipOptions={{ position: 'left', at: 'left top' }}
          style={{ width: '42px', height: '42px' }}
        >
          <i className="pi pi-file p-overlay-badge">
            {value?.summaryReportRevises?.length > 0 && (
              <Badge value={value?.summaryReportRevises?.length} severity="danger" />
            )}
          </i>
        </Button>
      </Link>
    </div>
  );

  return (
    <Dialog
      header={t('project.viewList.midtermDocumentDialog.title')}
      visible={visible}
      onHide={() => setVisible(false)}
      draggable={false}
      style={{ maxWidth: '52rem' }}
      className="w-full"
    >
      <table className="w-full view-history-midterm-table">
        <thead>
          <tr>
            <th style={{ width: '4%' }} className="text-center">
              {t('project.viewList.midtermDocumentDialog.no')}
            </th>
            <th style={{ width: '33%' }} className="text-center">
              {t('project.viewList.midtermDocumentDialog.version')}
            </th>
            <th style={{ width: '15%' }} className="text-center">
              {t('project.viewList.midtermDocumentDialog.submitDate')}
            </th>
            <th style={{ width: '25%' }} className="text-center">
              {t('project.viewList.midtermDocumentDialog.councilResult.label')}
            </th>
            <th className="text-center">{t('project.viewList.midtermDocumentDialog.action')}</th>
          </tr>
        </thead>
        <tbody>
          {midtermDocuments?.length > 0 ? (
            midtermDocuments.map((midtermDocument, index) => (
              <tr key={midtermDocument.id}>
                <td className="text-center">{index + 1}</td>
                <td className="text-center">{formatName(midtermDocument)}</td>
                <td className="text-center">{formatSubmitDate(midtermDocument)}</td>
                <td className="text-center">{formatCouncilResult(midtermDocument)}</td>
                <td className="text-center">{formatAction(midtermDocument)}</td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan={5} className="text-center">
                {t('project.viewList.midtermDocumentDialog.noMidtermDocument')}
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </Dialog>
  );
}

MidtermDocumentDialog.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  midtermDocuments: PropTypes.array.isRequired,
  visible: PropTypes.bool.isRequired,
  setVisible: PropTypes.func.isRequired,
};
export default MidtermDocumentDialog;
