import Layout from 'layout';
import { LayoutProvider } from 'layout/context/layoutcontext';
import { SubmitAcceptanceDocuments } from 'features/Project/pages';
import { Mode } from 'constant';

function AcceptanceDocumentEditPage() {
  return (
    <LayoutProvider>
      <Layout
        style={{
          maxHeight: 'calc(100vh - 9rem)',
          overflowY: 'auto',
          borderRadius: '12px',
          border: '1px solid var(--surface-border)',
          backgroundColor: 'white',
        }}
      >
        <SubmitAcceptanceDocuments mode={Mode.EDIT} />
      </Layout>
    </LayoutProvider>
  );
}

export default AcceptanceDocumentEditPage;
