/* eslint-disable indent */
import moment from 'moment';
import {
  PROJECT_ROLES,
  RESEARCH_FIELDS_WITH_ADDITIONAL_FUNDING_FOR_KPI,
  TIMEZONE_VN,
} from 'constant';
import { PRODUCT_DETAIL_FIELD } from 'constant/summary-report.constant';

export const addHours = (date, hours) => {
  date.setHours(date.getHours() + hours);

  return date;
};

export const getEndingTimeOfDate = (date) => {
  date.setHours(23);
  date.setMinutes(59);
  date.setSeconds(59);

  return date;
};

export const getDirtyValues = (_dirtyFields, allValues) => {
  if (_dirtyFields === true || Array.isArray(_dirtyFields)) {
    return allValues;
  }

  // Object
  return Object.fromEntries(
    Object.keys(_dirtyFields).map((key) => [key, getDirtyValues(_dirtyFields[key], allValues[key])])
  );
};

export const removeFalsyValue = (obj) => {
  const newObj = { ...obj };
  Object.keys(newObj).forEach((key) => {
    if (!newObj[key]) {
      delete newObj[key];
    }
  });
  return newObj;
};

export const mapErrorDialog = (t, error) => {
  if (error && error?.code && !t(`errorMessage.${error.code}`).startsWith('errorMessage')) {
    return t(`errorMessage.${error.code}`);
  }
  return error?.message ?? '';
};

export const mapError = (t, error) =>
  error.response &&
  error.response.data?.code &&
  !t(`errorMessage.${error.response.data.code}`).startsWith('errorMessage')
    ? t(`errorMessage.${error.response.data.code}`)
    : error.response && error.response.data?.message;

export const isOrganizationChange = (item) =>
  item?.chief !== item.organization?.chief ||
  item?.phone !== item.organization?.phone ||
  item?.fax !== item.organization?.fax ||
  item?.email !== item.organization?.email ||
  item?.bankAccount !== item.organization?.bankAccount ||
  item?.bank !== item.organization?.bank;

export const setOrganizationValues = (setValue, key, organization, type) => {
  setValue(`${key}.id`, organization?.id ?? '');
  setValue(`${key}.name`, organization?.name ?? '', { shouldValidate: true });
  setValue(`${key}.englishName`, organization?.englishName ?? '', { shouldValidate: true });
  setValue(`${key}.chief`, organization?.chief ?? '', { shouldValidate: true });
  setValue(`${key}.phone`, organization?.phone ?? '', { shouldValidate: true });
  setValue(`${key}.fax`, organization?.fax ?? '', { shouldValidate: true });
  setValue(`${key}.email`, organization?.email ?? '', { shouldValidate: true });
  setValue(`${key}.address`, organization?.address ?? '', { shouldValidate: true });
  setValue(`${key}.bankAccount`, organization?.bankAccount ?? '', { shouldValidate: true });
  setValue(`${key}.bank`, organization?.bank ?? '', { shouldValidate: true });
  setValue(`${key}.type`, type);
};

export const setResearcherValues = (setValue, key, organizationId) => {
  setValue(`${key}.organizationId`, organizationId ?? '');
};

export const formatFileName = (fileName = '') => {
  const tokens = fileName.split('__');
  if (tokens?.length > 1) tokens.splice(0, 1);

  return tokens.join('__');
};

export const checkOverdueDatetime = (datetime) => {
  if (!datetime) return false;
  const deadline = new Date(datetime).toLocaleString('en-ZA', {
    timeZone: TIMEZONE_VN,
  });
  const currentTime = new Date().toLocaleString('en-ZA', { timeZone: TIMEZONE_VN });

  if (currentTime > deadline) {
    return true;
  }
  return false;
};

export const getMaxDate = (...dates) => {
  const maxDateInMiliseconds = Math.max(...dates.map((date) => new Date(date || null).getTime()));
  return maxDateInMiliseconds ? new Date(maxDateInMiliseconds) : null;
};

export const checkPermissionEditProjectProposal = (projectProposal, reviseLog) => {
  // Lấy max Hạn sơ tuyển (đơn vị) của projectProposal và của program
  const organizationPrequalificationDeadline = getMaxDate(
    projectProposal?.organizationPrequalificationDeadline,
    projectProposal?.program?.organizationPrequalificationDeadline
  );

  //
  let explanationDeadline = reviseLog?.deadline;
  if (reviseLog?.type === 'REQUEST_EXPLANATION_PREQUALIFICATION') {
    explanationDeadline = getMaxDate(
      reviseLog?.deadline,
      projectProposal?.program?.staffPrequalificationDeadline
    );
  }

  const reviseLogEditPermission =
    (reviseLog?.type === 'REQUEST_UPDATE' &&
      !checkOverdueDatetime(organizationPrequalificationDeadline)) ||
    ([
      'REQUEST_EXPLANATION_PREQUALIFICATION',
      'REQUEST_EXPLANATION_SPECIALIZATION',
      'REQUEST_EXPLANATION_FINANCE',
    ].includes(reviseLog?.type) &&
      reviseLog?.isEditAllowed &&
      !checkOverdueDatetime(explanationDeadline));

  const projectProposalEditPermission =
    projectProposal?.isEditable === 1 &&
    !checkOverdueDatetime(projectProposal?.explanationDeadline);

  return reviseLogEditPermission && projectProposalEditPermission;
};

export const getAcadamicLevelShortHand = (academicRank, academicDegree, t, isShort) => {
  let rank = '';
  let degree = '';

  if (academicRank) {
    rank = isShort
      ? t(`academicRank.${academicRank}Short`)
      : `${t(`academicRank.${academicRank}`)},`;
  }

  if (academicDegree) {
    degree = `${t(`academicDegree.${academicDegree}`)},`;
    if (isShort) {
      degree = t(`academicDegree.${academicDegree}Short`);
    }
  }

  return `${`${rank} ${degree}`.trim()} `;
};

export const toCamelCaseString = (str = '') => {
  if (!str) return '';

  const result = str.toLowerCase().replace(/^([A-Z])|[\s-_]+(\w)/g, (match, p1, p2) => {
    if (p2) return p2.toUpperCase();
    return p1.toLowerCase();
  });

  return result;
};

export const toNonAccentVietnamese = (str) => {
  let result = str;
  result = result.replace(/A|Á|À|Ã|Ạ|Â|Ấ|Ầ|Ẫ|Ậ|Ă|Ắ|Ằ|Ẵ|Ặ/g, 'A');
  result = result.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, 'a');
  result = result.replace(/E|É|È|Ẽ|Ẹ|Ê|Ế|Ề|Ễ|Ệ/, 'E');
  result = result.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, 'e');
  result = result.replace(/I|Í|Ì|Ĩ|Ị/g, 'I');
  result = result.replace(/ì|í|ị|ỉ|ĩ/g, 'i');
  result = result.replace(/O|Ó|Ò|Õ|Ọ|Ô|Ố|Ồ|Ỗ|Ộ|Ơ|Ớ|Ờ|Ỡ|Ợ/g, 'O');
  result = result.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, 'o');
  result = result.replace(/U|Ú|Ù|Ũ|Ụ|Ư|Ứ|Ừ|Ữ|Ự/g, 'U');
  result = result.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, 'u');
  result = result.replace(/Y|Ý|Ỳ|Ỹ|Ỵ/g, 'Y');
  result = result.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, 'y');
  result = result.replace(/Đ/g, 'D');
  result = result.replace(/đ/g, 'd');
  // Some system encode vietnamese combining accent as individual utf-8 characters
  result = result.replace(/\u0300|\u0301|\u0303|\u0309|\u0323/g, ''); // Huyền sắc hỏi ngã nặng
  result = result.replace(/\u02C6|\u0306|\u031B/g, ''); // Â, Ê, Ă, Ơ, Ư
  return result;
};
export const getContactOrganizationName = (organizations, contact, t) => {
  const organization = organizations?.find((item) => item?.id === contact?.organizationId) ?? null;

  if (organization?.englishName === 'Other') {
    return contact?.specificOrganizationName;
  }

  return t('lang') === 'vi' ? organization?.name : organization?.englishName;
};

export const getContactOrganizationNameV2 = (organization, contact, t) => {
  if (organization?.englishName === 'Other') {
    return contact?.specificOrganizationName;
  }

  return t('lang') === 'vi' ? organization?.name : organization?.englishName;
};

export const findMaxItemByKey = (data = [], key = '') => {
  const max = data.reduce((maxItem, cur) => (cur?.[key] > maxItem?.[key] ? cur : maxItem));
  return max;
};

export const modifyBindingProjectProposalGeneralData = (data) => {
  if (!data) return null;

  const researchFields = data.projectProposalResearchFields.map((e) => ({
    priority: e?.priority,
    researchFieldId: e.researchField.id,
    researchLine: e.researchLine,
  }));

  const modifiedResearchFields = [];
  researchFields.forEach((e) => {
    modifiedResearchFields[e.priority - 1] = e;
  });

  const researchExpense = {
    totalExpenditure: data.totalExpenditure,
    nationalUniversityTotalExpenditure: data.nationalUniversityTotalExpenditure,
    nationalUniversityAllocatedExpenditure: data.nationalUniversityAllocatedExpenditure,
    nationalUniversityNonallocatedExpenditure: data.nationalUniversityNonallocatedExpenditure,
    fundingExpenditure: data.fundingExpenditure,
    fundingExpenditureSelf: data.fundingExpenditureSelf,
    fundingExpenditureOther: data.fundingExpenditureOther,
    otherSponsorOrganization: data.otherSponsorOrganization,
    bonusExpenditure: data.bonusExpenditure,
  };
  const owner = data.projectProposalContacts.find(
    (e) => PROJECT_ROLES.OWNER === e.projectRole.code
  );

  const modifiedOwner = {
    ...owner,
    workplace: data?.workplace ?? '',
    dob: owner?.dob ? new Date(owner?.dob) : null,
    dateOfIssue: owner?.dateOfIssue ? new Date(owner?.dateOfIssue) : null,
    researchLines: owner?.researchLines ?? [],
    scientificProfileUrl: owner?.contact?.user?.scientificProfileUrl,
  };

  const modifiedLead = {
    ...data.projectProposalOrganizations.find((e) => e.type === 'LEAD'),
  };
  if (modifiedLead?.englishName === 'Other') {
    modifiedLead.specificName = modifiedLead.name;
  }

  const partners = data.projectProposalOrganizations
    .filter((e) => e.type === 'PARTNER')
    ?.map((e) => ({
      ...e,
      specificName: e?.englishName === 'Other' ? e?.name : '',
      file: e?.partnerConfirmationUrl ? 'fileUploaded' : null,
    }));

  const scientificSecretaries = data.projectProposalContacts
    .filter((e) => PROJECT_ROLES.SECRETARY === e.projectRole.code)
    ?.map((e) => ({
      contact: e.contact,
      researchLines: e?.researchLines ?? [],
      assignedTask: e.assignedTask,
      isAuthorized: e.isAuthorized,
      organizationId: e.organization.id,
      projectRoleId: e.projectRole.id,
      fullname: e?.fullname,
      gender: e?.gender,
      academicRank: e?.academicRank,
      academicDegree: e?.academicDegree,
      email: e?.email,
      specificOrganizationName:
        e?.organization?.englishName === 'Other' ? e?.specificOrganizationName : null,
    }));

  const mainParticipants = data.projectProposalContacts
    .filter((e) => PROJECT_ROLES.MAIN_PARTICIPANT === e.projectRole.code)
    ?.map((e) => ({
      contact: e.contact,
      researchLines: e?.researchLines ?? [],
      assignedTask: e.assignedTask,
      isAuthorized: e.isAuthorized,
      organizationId: e.organization.id,
      projectRoleId: e.projectRole.id,
      fullname: e?.fullname,
      gender: e?.gender,
      academicRank: e?.academicRank,
      academicDegree: e?.academicDegree,
      email: e?.email,
      specificOrganizationName:
        e?.organization?.englishName === 'Other' ? e?.specificOrganizationName : null,
    }));
  const participants = data.projectProposalContacts
    .filter((e) => PROJECT_ROLES.PARTICIPANT === e.projectRole.code)
    ?.map((e) => ({
      contact: e.contact,
      researchLines: e?.researchLines ?? [],
      assignedTask: e.assignedTask,
      isAuthorized: e.isAuthorized,
      organizationId: e.organization.id,
      projectRoleId: e.projectRole.id,
      fullname: e?.fullname,
      gender: e?.gender,
      academicRank: e?.academicRank,
      academicDegree: e?.academicDegree,
      email: e?.email,
      specificOrganizationName:
        e?.organization?.englishName === 'Other' ? e?.specificOrganizationName : null,
    }));
  const technicians = data.projectProposalContacts
    .filter((e) => PROJECT_ROLES.TECHNICIAN === e.projectRole.code)
    ?.map((e) => ({
      contact: e.contact,
      researchLines: e?.researchLines ?? [],
      assignedTask: e.assignedTask,
      isAuthorized: e.isAuthorized,
      organizationId: e.organization.id,
      projectRoleId: e.projectRole.id,
      fullname: e?.fullname,
      gender: e?.gender,
      academicRank: e?.academicRank,
      academicDegree: e?.academicDegree,
      email: e?.email,
      specificOrganizationName:
        e?.organization?.englishName === 'Other' ? e?.specificOrganizationName : null,
    }));

  const domesticExperts = data.projectProposalContacts
    .filter((e) => PROJECT_ROLES.DOMESTIC_EXPERT === e.projectRole.code)
    ?.map((e) => ({
      contact: e.contact,
      researchLines: e?.researchLines ?? [],
      assignedTask: e.assignedTask,
      isAuthorized: e.isAuthorized,
      organizationId: e.organization.id,
      projectRoleId: e.projectRole.id,
      fullname: e?.fullname,
      gender: e?.gender,
      academicRank: e?.academicRank,
      academicDegree: e?.academicDegree,
      email: e?.email,
      specificOrganizationName:
        e?.organization?.englishName === 'Other' ? e?.specificOrganizationName : null,
    }));
  const internationalExperts = data.projectProposalContacts
    .filter((e) => PROJECT_ROLES.INTERNATIONAL_EXPERT === e.projectRole.code)
    ?.map((e) => ({
      contact: e.contact,
      researchLines: e?.researchLines ?? [],
      assignedTask: e.assignedTask,
      isAuthorized: e.isAuthorized,
      organizationId: e.organization.id,
      projectRoleId: e.projectRole.id,
      fullname: e?.fullname,
      gender: e?.gender,
      academicRank: e?.academicRank,
      academicDegree: e?.academicDegree,
      email: e?.email,
      specificOrganizationName:
        e?.organization?.englishName === 'Other' ? e?.specificOrganizationName : null,
    }));

  return {
    vietnameseName: data.vietnameseName,
    englishName: data.englishName,
    projectType: data.projectType,
    pubProjectProposalId: data?.pubProjectProposalId,
    pubProjectProposal: data?.pubProjectProposal,
    projectProposalResearchFields: modifiedResearchFields,
    recommendedExperts: data.recommendedExperts,
    implementationMonths: data.implementationMonths,
    yearProposed: data.yearProposed,
    bonusExpenditure: data.bonusExpenditure,
    researchTypesId: data.researchType.id,
    researchExpense,
    owner: modifiedOwner,
    ownerContactId: modifiedOwner?.contact?.id,
    lead: modifiedLead,
    partners,
    scientificSecretaries,
    mainParticipants,
    participants,
    technicians,
    domesticExperts,
    internationalExperts,
  };
};

export const modifyBindingProjectProposalResearchResultData = (data) => {
  if (!data) return null;

  const projectProposalResearchKpis = data.projectProposalResearchKpis.reduce(
    (obj, cur) => ({
      ...obj,
      [cur.researchKpi.id]: {
        id: cur.id,
        quantity: cur.quantity,
        content: cur.content,
      },
    }),
    {}
  );

  return {
    softProducts: data.softProducts,
    hardProducts: data.hardProducts,
    knowledgeContributionLevel: data.knowledgeContributionLevel ?? '',
    practicalPolicyImpact: data.practicalPolicyImpact ?? '',
    teamDevelopmentLevel: data.teamDevelopmentLevel ?? '',
    researchTransferability: data.researchTransferability ?? '',
    qualityComparison: data?.qualityComparison ?? '',
    projectProposalResearchKpis,
    proposedExpenditureByKpis: data?.proposedExpenditureByKpis,
  };
};

export const modifyBindingProjectProposalExpenditureData = (data) => {
  const modyfiedProposedExpenditureSummaries = data.proposedExpenditureSummaries.reduce(
    (obj, cur) => ({
      ...obj,
      [cur.proposedExpenditureType.id]: {
        id: cur.id,
        expenditureFromVNUHCM: cur.expenditureFromVNUHCM,
        expenditureFromFunding: cur.expenditureFromFunding,
      },
    }),
    {}
  );

  return {
    id: data.id,
    proposedExpenditureSummaries: modyfiedProposedExpenditureSummaries,
  };
};

export const modifyBindingProjectProposalResearchDescriptionData = (data) => {
  const proofFiles = data?.researchDescriptionProofFiles ?? [];
  return {
    otherProofsAndAppendicesFiles: proofFiles,
  };
};

export const modifyProjectProposalGeneralCreateData = (data) => {
  const modifyResearchFields = data.general?.projectProposalResearchFields
    .map((e, i) => ({ ...e, priority: i + 1 }))
    ?.filter((e) => e.researchFieldId);

  const modifyRecommendedExperts = data.general?.recommendedExperts?.map((e) => ({
    ...e,
    phone: e.phone?.replace(/\s/g, '') ?? '',
  }));

  const owner = {
    ...data.general.owner,
    dob: data.general?.owner?.dob ?? null,
    dateOfIssue: data.general?.owner?.dateOfIssue ?? null,
    citizenId: data.general?.owner?.citizenId?.replace(/\s/g, '') ?? '',
    taxIdentificationNumber: data.general?.owner?.taxIdentificationNumber?.replace(/\s/g, '') ?? '',
    bankAccount: data.general?.owner?.bankAccount?.replace(/\s/g, '') ?? '',
    mobile: data.general?.owner?.mobile?.replace(/\s/g, '') ?? '',
    contact: data.general?.owner?.contact,
    organizationId: data.general?.owner?.organizationId,
    researchLines: data.general?.owner?.researchLines ?? [],
    assignedTask: data.general?.owner?.assignedTask ?? '',
    projectRoleId: data.general?.owner?.projectRoleId,
  };
  delete owner.id;
  const modifyOwner = removeFalsyValue(owner);
  const projectProposalOrganizations = [
    {
      organizationId: data.general.lead.organizationId,
      name:
        data.general.lead.englishName === 'Other'
          ? data.general.lead.specificName
          : data.general.lead.name,
      englishName: data.general.lead.englishName,
      chief: data.general.lead.chief,
      phone: data.general.lead.phone,
      fax: data.general.lead.fax,
      email: data.general.lead.email,
      address: data.general.lead.address,
      bankAccount: data.general.lead.bankAccount,
      bank: data.general.lead.bank,
      type: 'LEAD',
    },
    ...data.general.partners.map((partner) => ({
      organizationId: partner.organizationId,
      name: partner?.englishName === 'Other' ? partner?.specificName : partner?.name,
      englishName: partner.englishName,
      chief: partner.chief,
      fax: partner.fax,
      phone: partner.phone,
      email: partner.email,
      address: partner.address,
      bankAccount: partner.bankAccount,
      bank: partner.bank,
      partnerContribution: partner.partnerContribution,
      type: 'PARTNER',
    })),
  ];

  const projectProposalContacts = [
    {
      ...modifyOwner,
    },
    ...data.general.scientificSecretaries,
    ...data.general.mainParticipants,
    ...data.general.participants,
    ...data.general.technicians,
    ...data.general.domesticExperts,
    ...data.general.internationalExperts,
  ];

  const proposedExpenditureSummaries = data?.expenditure?.proposedExpenditureSummaries ?? null;

  if (proposedExpenditureSummaries) {
    Object.keys(proposedExpenditureSummaries).forEach((key) => {
      proposedExpenditureSummaries[key].expenditureFromVNUHCM =
        proposedExpenditureSummaries[key]?.expenditureFromVNUHCM ?? 0;
      proposedExpenditureSummaries[key].expenditureFromFunding =
        proposedExpenditureSummaries[key]?.expenditureFromFunding ?? 0;
    });
  }

  const newData = {
    ...data.general,
    recommendedExperts: modifyRecommendedExperts,
    projectProposalResearchFields: modifyResearchFields,
    ...data.general.researchExpense,
    ...modifyOwner,
    projectProposalOrganizations: [...projectProposalOrganizations],
    projectProposalContacts: [...projectProposalContacts],
    ...data.researchResult,
    proposedExpenditureSummaries,
    ownerContactId: data.general?.owner?.id,
    projectProposalStatusId: 'new',
  };
  delete newData.owner;

  delete newData.owners;
  delete newData.scientificSecretaries;
  delete newData.mainParticipants;
  delete newData.participants;
  delete newData.technicians;
  delete newData.domesticExperts;
  delete newData.internationalExperts;
  delete newData?.pubProjectProposal;

  delete newData.lead;
  delete newData.partners;

  delete newData.researchExpense;

  return newData;
};

export const modifyProjectProposalGeneralUpdateData = (data) => {
  const projectProposalResearchFields = data.general?.projectProposalResearchFields
    .map((e, i) => ({ ...e, priority: i + 1 }))
    ?.filter((e) => e.researchFieldId);

  const modifyRecommendedExperts = data.general?.recommendedExperts?.map((e) => ({
    ...e,
    phone: e.phone?.replace(/\s/g, '') ?? '',
  }));

  const owner = {
    ...data.general.owner,
    dob: data.general?.owner?.dob ?? null,
    dateOfIssue: data.general?.owner?.dateOfIssue ?? null,
    citizenId: data.general?.owner?.citizenId?.replace(/\s/g, '') ?? '',
    taxIdentificationNumber: data.general?.owner?.taxIdentificationNumber?.replace(/\s/g, '') ?? '',
    bankAccount: data.general?.owner?.bankAccount?.replace(/\s/g, '') ?? '',
    mobile: data.general?.owner?.mobile?.replace(/\s/g, '') ?? '',
    researchLines: data.general?.owner?.researchLines ?? [],
  };
  delete owner.id;

  const projectProposalContacts = [
    {
      ...owner,
    },
    ...data.general.scientificSecretaries,
    ...data.general.mainParticipants,
    ...data.general.participants,
    ...data.general.technicians,
    ...data.general.domesticExperts,
    ...data.general.internationalExperts,
  ];

  const projectProposalOrganizations = [
    {
      organizationId: data.general.lead.organizationId,
      name:
        data.general.lead.englishName === 'Other'
          ? data.general.lead.specificName
          : data.general.lead.name,
      englishName: data.general.lead.englishName,
      chief: data.general.lead.chief,
      phone: data.general.lead.phone,
      fax: data.general.lead.fax,
      email: data.general.lead.email,
      address: data.general.lead.address,
      bankAccount: data.general.lead.bankAccount,
      bank: data.general.lead.bank,
      type: 'LEAD',
    },
    ...data.general.partners.map((partner) => ({
      organizationId: partner.organizationId,
      name: partner?.englishName === 'Other' ? partner?.specificName : partner?.name,
      englishName: partner.englishName,
      chief: partner.chief,
      fax: partner.fax,
      phone: partner.phone,
      email: partner.email,
      address: partner.address,
      bankAccount: partner.bankAccount,
      bank: partner.bank,
      partnerContribution: partner.partnerContribution,
      type: 'PARTNER',
    })),
  ];

  const newData = {
    ...data.general,
    recommendedExperts: modifyRecommendedExperts,
    projectProposalResearchFields,
    projectProposalContacts: [...projectProposalContacts],
    ...data.general.researchExpense,
    projectProposalOrganizations: [...projectProposalOrganizations],
    ownerContactId: data.general.ownerContactId,
    workplace: owner?.workplace,
  };
  delete newData.owner;

  delete newData.owners;
  delete newData.scientificSecretaries;
  delete newData.mainParticipants;
  delete newData.participants;
  delete newData.technicians;
  delete newData.domesticExperts;
  delete newData.internationalExperts;

  delete newData.lead;
  delete newData.partners;

  delete newData.researchExpense;
  delete newData?.pubProjectProposal;

  return newData;
};

export const modifyProjectProposalPartnersFormData = (partners) => {
  const formData = new FormData();
  const partnerInfos = [];

  partners?.forEach((partner, index) => {
    const partnerData = {
      // phải check do có thể id này là do useFieldArray sinh ra
      id: partner?.partnerConfirmationUrl ? partner?.id : null,
      index,
      organizationId: partner?.organizationId,
      name: partner?.englishName === 'Other' ? partner?.specificName : partner?.name,
      englishName: partner?.englishName,
      chief: partner?.chief,
      fax: partner?.fax,
      phone: partner?.phone,
      email: partner?.email,
      address: partner?.address,
      bankAccount: partner?.bankAccount,
      bank: partner?.bank,
      partnerContribution: partner?.partnerContribution,
      type: 'PARTNER',
      partnerConfirmationUrl: partner?.partnerConfirmationUrl,
    };

    if (partner.file && partner?.file !== 'fileUploaded') {
      formData.append('files', partner.file);
      partnerData.isToUpload = true;
    }

    partnerInfos.push(partnerData);
  });

  formData.append('partnerInfos', JSON.stringify(partnerInfos));

  return formData;
};

export const modifyProjectProposalResearchResultUpdateData = (data) => {
  const newData = {
    ...data.researchResult,
  };

  return newData;
};

export const modifyProjectProposalExpenditureUpdateData = (data) => {
  const proposedExpenditureSummaries = data?.expenditure?.proposedExpenditureSummaries ?? null;

  if (proposedExpenditureSummaries) {
    Object.keys(proposedExpenditureSummaries).forEach((key) => {
      proposedExpenditureSummaries[key].expenditureFromVNUHCM =
        proposedExpenditureSummaries[key]?.expenditureFromVNUHCM ?? 0;
      proposedExpenditureSummaries[key].expenditureFromFunding =
        proposedExpenditureSummaries[key]?.expenditureFromFunding ?? 0;
    });
  }

  return { proposedExpenditureSummaries };
};

export const modifyProjectProposalResearchDescriptionProofsFormData = (proofFiles) => {
  const formData = new FormData();
  const fileInfos = [];

  proofFiles.forEach((proofFile, index) => {
    const fileInfo = {
      id: proofFile.id,
      index,
    };
    if (proofFile?.fileName) fileInfo.fileName = proofFile?.fileName;

    if (proofFile?.file) {
      formData.append('files', proofFile?.file);
      fileInfo.isToUpload = true;
    }

    fileInfos.push(fileInfo);
  });

  formData.append('fileInfos', JSON.stringify(fileInfos));

  return formData;
};

export const modifyProjectProposalGeneralReviewData = (
  general,
  researchFields,
  researchTypes,
  organizations,
  projectRoles
) => {
  const projectProposalResearchFields =
    general?.projectProposalResearchFields
      .filter((e) => e.researchFieldId)
      .map((researchField) => {
        const researchFieldData = researchFields.find(
          (e) => e.id === researchField.researchFieldId
        );
        return { ...researchField, researchField: researchFieldData ?? null };
      }) ?? [];

  const researchType = researchTypes.find((e) => e.id === general?.researchTypesId) ?? null;

  const partners =
    general?.partners.map((partner) => ({
      ...partner,
      name: partner?.englishName === 'Other' ? partner?.specificName : partner?.name,
      partnerConfirmationUrl: partner?.partnerConfirmationUrl,
      partnerContribution: partner?.partnerContribution,
    })) ?? [];

  const projectProposalOrganizations = [
    {
      ...general.lead,
      name: general?.lead.englishName === 'Other' ? general?.lead.specificName : general?.lead.name,
    },
    ...partners,
  ];

  const owner = {
    ...general?.owner,
    dob: general?.owner?.dob ?? null,
    dateOfIssue: general?.owner?.dateOfIssue ?? null,
    citizenId: general?.owner?.citizenId?.replace(/\s/g, '') ?? '',
    taxIdentificationNumber: general?.owner?.taxIdentificationNumber?.replace(/\s/g, '') ?? '',
    bankAccount: general?.owner?.bankAccount?.replace(/\s/g, '') ?? '',
    mobile: general?.owner?.mobile?.replace(/\s/g, '') ?? '',
    organization: { ...organizations.find((item) => item.id === general?.owner?.organizationId) },
  };

  const scientificSecretaries =
    general?.scientificSecretaries?.map((researcher) => ({
      ...researcher,
      organization: organizations.find((org) => org.id === researcher.organizationId),
      projectRole: projectRoles.find((projr) => projr.id === researcher.projectRoleId),
    })) ?? [];

  const mainParticipants =
    general?.mainParticipants?.map((researcher) => ({
      ...researcher,
      organization: organizations.find((org) => org.id === researcher.organizationId),
      projectRole: projectRoles.find((projr) => projr.id === researcher.projectRoleId),
    })) ?? [];

  const participants =
    general?.participants?.map((researcher) => ({
      ...researcher,
      organization: organizations.find((org) => org.id === researcher.organizationId),
      projectRole: projectRoles.find((projr) => projr.id === researcher.projectRoleId),
    })) ?? [];

  const technicians =
    general?.technicians?.map((researcher) => ({
      ...researcher,
      organization: organizations.find((org) => org.id === researcher.organizationId),
      projectRole: projectRoles.find((projr) => projr.id === researcher.projectRoleId),
    })) ?? [];

  const domesticExperts =
    general?.domesticExperts?.map((researcher) => ({
      ...researcher,
      organization: organizations.find((org) => org.id === researcher.organizationId),
      projectRole: projectRoles.find((projr) => projr.id === researcher.projectRoleId),
    })) ?? [];

  const internationalExperts =
    general?.internationalExperts?.map((researcher) => ({
      ...researcher,
      organization: organizations.find((org) => org.id === researcher.organizationId),
      projectRole: projectRoles.find((projr) => projr.id === researcher.projectRoleId),
    })) ?? [];

  const projectProposalContacts = [
    { ...owner },
    ...scientificSecretaries,
    ...mainParticipants,
    ...participants,
    ...technicians,
    ...domesticExperts,
    ...internationalExperts,
  ];

  const researchExpense = general?.researchExpense ?? {};

  return {
    ...general,
    owner,
    ...researchExpense,
    projectProposalResearchFields,
    projectProposalOrganizations,
    projectProposalContacts,
    researchType,
  };
};

export const modifyProjectProposalResearchResultReviewData = (
  researchResult,
  researchKpis,
  projectProposalResearchKpisInDB,
  submittedAt
) => {
  if (researchResult?.projectProposalResearchKpis === undefined) {
    return {
      softProducts: researchResult?.softProducts ?? [],
      hardProducts: researchResult?.hardProducts ?? [],
      qualityComparison: researchResult?.qualityComparison ?? '',
      knowledgeContributionLevel: researchResult?.knowledgeContributionLevel ?? '',
      practicalPolicyImpact: researchResult?.practicalPolicyImpact ?? '',
      teamDevelopmentLevel: researchResult?.teamDevelopmentLevel ?? '',
      researchTransferability: researchResult?.researchTransferability ?? '',
      projectProposalResearchKpis: [],
    };
  }

  let projectProposalResearchKpis = [];
  if (!submittedAt) {
    projectProposalResearchKpis =
      researchKpis?.map((kpi) => ({
        id: kpi.id,
        quantity: researchResult?.projectProposalResearchKpis?.[kpi.id]?.quantity ?? 0,
        content: researchResult?.projectProposalResearchKpis?.[kpi.id]?.content ?? '',
        researchKpi: {
          ...kpi,
        },
      })) ?? [];
  } else {
    projectProposalResearchKpis =
      projectProposalResearchKpisInDB?.map((item) => ({
        id: item.researchKpi.id,
        quantity: researchResult?.projectProposalResearchKpis?.[item.researchKpi.id]?.quantity ?? 0,
        content: researchResult?.projectProposalResearchKpis?.[item.researchKpi.id]?.content ?? '',
        researchKpi: {
          ...item.researchKpi,
        },
      })) ?? [];
  }

  return {
    softProducts: researchResult?.softProducts ?? [],
    hardProducts: researchResult?.hardProducts ?? [],
    knowledgeContributionLevel: researchResult?.knowledgeContributionLevel ?? '',
    practicalPolicyImpact: researchResult?.practicalPolicyImpact ?? '',
    teamDevelopmentLevel: researchResult?.teamDevelopmentLevel ?? '',
    researchTransferability: researchResult?.researchTransferability ?? '',
    qualityComparison: researchResult?.qualityComparison ?? '',
    projectProposalResearchKpis,
  };
};

export const modifyProjectProposalExpenditureReviewData = (expenditure, expenditureTypes) => {
  if (expenditure?.proposedExpenditureSummaries === undefined) {
    return { proposedExpenditureSummaries: [] };
  }

  const proposedExpenditureSummaries =
    expenditureTypes?.map((type) => ({
      id: type?.id,
      expenditureFromVNUHCM:
        expenditure?.proposedExpenditureSummaries[type?.id]?.expenditureFromVNUHCM ?? 0,
      expenditureFromFunding:
        expenditure?.proposedExpenditureSummaries[type?.id]?.expenditureFromFunding ?? 0,
      proposedExpenditureType: {
        ...type,
      },
    })) ?? [];

  return { proposedExpenditureSummaries };
};

// SCIENTIFIC PROFILE
export const modifyBindingScientificProfileGeneralData = (data) => {
  if (!data) return null;

  const contact = {
    fullname: data?.fullname,
    organizationId: data?.organization?.id,
    organizationCode: data?.organization?.code,
  };

  const general = {
    ...data?.scientificProfile,
    dob: data?.scientificProfile?.dob ? new Date(data?.scientificProfile?.dob) : null,
  };

  return { ...contact, ...general };
};
export const modifyBindingScientificProfileAcademicHistoryData = (data) => {
  if (!data) return null;

  const universities = data?.scientificProfile?.academicHistory?.universities?.map((item) => ({
    ...item,
    start: item?.start ? new Date(item?.start) : null,
    end: item?.end ? new Date(item?.end) : null,
  }));
  const masters = data?.scientificProfile?.academicHistory?.masters?.map((item) => ({
    ...item,
    start: item?.start ? new Date(item?.start) : null,
    end: item?.end ? new Date(item?.end) : null,
  }));
  const doctorates = data?.scientificProfile?.academicHistory?.doctorates?.map((item) => ({
    ...item,
    start: item?.start ? new Date(item?.start) : null,
    end: item?.end ? new Date(item?.end) : null,
  }));
  const scienceDoctorates = data?.scientificProfile?.academicHistory?.scienceDoctorates?.map(
    (item) => ({
      ...item,
      start: item?.start ? new Date(item?.start) : null,
      end: item?.end ? new Date(item?.end) : null,
    })
  );

  return {
    universities,
    masters,
    doctorates,
    scienceDoctorates,
  };
};
export const modifyBindingScientificProfileWorkHistoryData = (data) => {
  if (!data) return null;

  const workHistory = data?.scientificProfile?.workHistory?.map((item) => ({
    ...item,
    start: item?.start ? new Date(item?.start) : null,
    end: item?.end ? new Date(item?.end) : null,
  }));

  return workHistory;
};
export const modifyBindingScientificProfileResearchActivitiesData = (data) => {
  if (!data) return null;

  const researchActivities = data?.scientificProfile?.projects?.map((item) => ({
    ...item,
    acceptanceDate: item?.acceptanceDate ? new Date(item?.acceptanceDate) : null,
  }));

  return researchActivities;
};
export const modifyBindingScientificProfileGuidingActivitiesData = (data) => {
  if (!data) return null;

  const guidingActivities = data?.scientificProfile?.thesisGuidances?.map((item) => ({
    ...item,
  }));

  return guidingActivities;
};
export const modifyBindingScientificProfileScientificWorksData = (data) => {
  if (!data) return null;

  // books
  const internationalBooks = data?.scientificProfile?.scientificProfileBooks
    ?.filter((item) => item?.book?.publicationType === 'INTERNATIONAL')
    .map((item) => ({
      ...item,
      internationalBook: {
        id: item?.book?.id,
        title: item?.book?.title,
      },
      book: {
        id: item?.book?.id,
        title: item?.book?.title,
        projectCode: item?.book?.projectCode,
        publisher: item?.book?.publisher,
        year: item?.book?.year,
        publicationType: item?.book?.publicationType,
      },
    }));
  const domesticBooks = data?.scientificProfile?.scientificProfileBooks
    ?.filter((item) => item?.book?.publicationType === 'DOMESTIC')
    .map((item) => ({
      ...item,
      domesticBook: {
        id: item?.book?.id,
        title: item?.book?.title,
      },
      book: {
        id: item?.book?.id,
        title: item?.book?.title,
        projectCode: item?.book?.projectCode,
        publisher: item?.book?.publisher,
        year: item?.book?.year,
        publicationType: item?.book?.publicationType,
      },
    }));

  // papers
  const internationalPapers = data?.scientificProfile?.scientificProfilePapers
    ?.filter((item) => item?.paper?.publicationType === 'INTERNATIONAL')
    .map((item) => ({
      ...item,
      internationalPaper: {
        id: item?.paper?.id,
        title: item?.paper?.title,
      },
      paper: {
        id: item?.paper?.id,
        title: item?.paper?.title,
        journal: item?.paper?.journal,
        journalAbbrev: item?.paper?.journalAbbrev,
        pages: item?.paper?.pages,
        authors: item?.paper?.authors,
        year: item?.paper?.year,
        projectCode: item?.paper?.projectCode,
        issn: item?.paper?.issn,
        isIsi: item?.paper?.isIsi,
        if: item?.paper?.if,
        publicationType: item?.paper?.publicationType,
        doi: item?.paper?.doi,
        citationCount: item?.paper?.citationCount,
        eid: item?.paper?.eid,
        pageRage: item?.paper?.pageRage,
        scopusId: item?.paper?.scopusId,
        pii: item?.paper?.pii,
      },
    }));
  const domesticPapers = data?.scientificProfile?.scientificProfilePapers
    ?.filter((item) => item?.paper?.publicationType === 'DOMESTIC')
    .map((item) => ({
      ...item,
      domesticPaper: {
        id: item?.paper?.id,
        title: item?.paper?.title,
      },
      paper: {
        id: item?.paper?.id,
        title: item?.paper?.title,
        journal: item?.paper?.journal,
        journalAbbrev: item?.paper?.journalAbbrev,
        pages: item?.paper?.pages,
        authors: item?.paper?.authors,
        year: item?.paper?.year,
        projectCode: item?.paper?.projectCode,
        issn: item?.paper?.issn,
        publicationType: item?.paper?.publicationType,
        scholarVerification: item?.paper?.scholarVerification,
      },
    }));
  const internationalConferencePapers = data?.scientificProfile?.scientificProfilePapers
    ?.filter((item) => item?.paper?.publicationType === 'INTERNATIONAL_CONFERENCE')
    .map((item) => ({
      ...item,
      internationalConferencePaper: {
        id: item?.paper?.id,
        title: item?.paper?.title,
      },
      paper: {
        id: item?.paper?.id,
        title: item?.paper?.title,
        authors: item?.paper?.authors,
        conference: item?.paper?.conference,
        start: item?.paper?.start ? new Date(item?.paper?.start) : null,
        end: item?.paper?.end ? new Date(item?.paper?.end) : null,
        venue: item?.paper?.venue,
        projectCode: item?.paper?.projectCode,
        isbn: item?.paper?.isbn,
        publicationType: item?.paper?.publicationType,
        doi: item?.paper?.doi,
        citationCount: item?.paper?.citationCount,
        eid: item?.paper?.eid,
        pageRage: item?.paper?.pageRage,
        scopusId: item?.paper?.scopusId,
        pii: item?.paper?.pii,
      },
    }));
  const domesticConferencePapers = data?.scientificProfile?.scientificProfilePapers
    ?.filter((item) => item?.paper?.publicationType === 'DOMESTIC_CONFERENCE')
    .map((item) => ({
      ...item,
      domesticConferencePaper: {
        id: item?.paper?.id,
        title: item?.paper?.title,
      },
      paper: {
        id: item?.paper?.id,
        title: item?.paper?.title,
        authors: item?.paper?.authors,
        conference: item?.paper?.conference,
        // start: item?.paper?.start,
        // end: item?.paper?.end,
        start: item?.paper?.start ? new Date(item?.paper?.start) : null,
        end: item?.paper?.end ? new Date(item?.paper?.end) : null,
        venue: item?.paper?.venue,
        projectCode: item?.paper?.projectCode,
        isbn: item?.paper?.isbn,
        publicationType: item?.paper?.publicationType,
        scholarVerification: item?.paper?.scholarVerification,
      },
    }));

  return {
    internationalBooks,
    domesticBooks,
    internationalPapers,
    domesticPapers,
    internationalConferencePapers,
    domesticConferencePapers,
  };
};
export const modifyBindingScientificProfileOthersData = (data) => {
  if (!data) return null;

  const awards = data?.scientificProfile?.awards?.map((item) => ({ ...item }));

  const patents = data?.scientificProfile?.patents?.map((item) => ({ ...item }));

  const usefulSolutions = data?.scientificProfile?.usefulSolutions?.map((item) => ({ ...item }));

  const transferredSolutions = data?.scientificProfile?.transferredSolutions?.map((item) => ({
    ...item,
  }));

  const programAttendances = data?.scientificProfile?.programAttendances?.map((item) => ({
    ...item,
  }));

  const conferenceAttendances = data?.scientificProfile?.conferenceAttendances?.map((item) => ({
    ...item,
    start: item?.start ? new Date(item?.start) : null,
    end: item?.end ? new Date(item?.end) : null,
  }));

  const universityVisitingStaff = data?.scientificProfile?.universityVisitingStaff?.map((item) => ({
    ...item,
    start: item?.start ? new Date(item?.start) : null,
    end: item?.end ? new Date(item?.end) : null,
  }));

  return {
    awards,
    patents,
    usefulSolutions,
    transferredSolutions,
    programAttendances,
    conferenceAttendances,
    universityVisitingStaff,
  };
};

export const modifyScientificProfileGeneralUpdateData = (data) => {
  if (!data?.general) return null;

  const general = {
    ...data?.general,
    dob: data?.general?.dob ?? null,
    officePhone: data?.general?.officePhone?.replace(/\s/g, '') ?? '',
    personalPhone: data?.general?.personalPhone?.replace(/\s/g, '') ?? '',
    foreignLanguages: data.general?.foreignLanguages ?? [],
    researchLines: data.general?.researchLines ?? [],
  };

  return general;
};
export const modifyScientificProfileAcademicHistoryUpdateData = (data) => {
  if (!data?.academicHistory) return null;

  return { ...data?.academicHistory };
};

// eslint-disable-next-line max-len
export const isMemberAllowedReview = (member) => member?.councilMemberRole?.isAllowedReview;

export const modifyScientificProfileWorkHistoryUpdateData = (data) => {
  if (!data?.workHistory) return null;

  const workHistory = data?.workHistory?.map((item) => ({
    ...item,
  }));

  return workHistory;
};

export const modifyScientificProfileResearchActivitiesUpdateData = (data) => {
  if (!data?.researchActivities) return null;

  const researchActivities = data?.researchActivities?.map((item) => ({
    ...item,
  }));

  return researchActivities;
};

export const modifyScientificProfileGuidingActivitiesUpdateData = (data) => {
  if (!data?.guidingActivities) return null;

  const guidingActivities = data?.guidingActivities?.map((item) => ({
    ...item,
  }));

  return guidingActivities;
};
export const modifyScientificProfileScientificWorksUpdateData = (data) => {
  if (!data?.scientificWorks) return null;

  const {
    internationalBooks,
    domesticBooks,
    internationalPapers,
    domesticPapers,
    internationalConferencePapers,
    domesticConferencePapers,
  } = { ...data?.scientificWorks };

  const scientificProfileBooksPreData = [...internationalBooks, ...domesticBooks];
  const scientificProfilePapersPreData = [
    ...internationalPapers,
    ...domesticPapers,
    ...internationalConferencePapers,
    ...domesticConferencePapers,
  ];

  const addDisplayOrder = (listData) =>
    listData.map((paper, index) => ({
      ...paper,
      displayOrder: index + 1,
    }));

  // if authors is array, join them to string
  const checkAuthors = (listData) =>
    listData.map((item) => {
      if (item.paper && Array.isArray(item.paper.authors)) {
        return {
          ...item,
          paper: {
            ...item.paper,
            authors: item.paper.authors.join(', '),
          },
        };
      }
      return item;
    });

  // add display order
  const scientificProfileBooks = addDisplayOrder(scientificProfileBooksPreData);
  let scientificProfilePapers = addDisplayOrder(scientificProfilePapersPreData);

  // check authors
  scientificProfilePapers = checkAuthors(scientificProfilePapers);

  return {
    scientificProfileBooks,
    scientificProfilePapers,
  };
};
export const modifyScientificProfileOthersUpdateData = (data) => {
  if (!data?.others) return null;

  return { ...data?.others };
};

export const modifyScientificProfilePreviewData = (data) => {
  const generalInfoSection = {
    ...data?.general,
    academicHistory: { ...data?.academicHistory },
    workHistory: data?.workHistory?.map((item) => ({ ...item })),
  };

  const researchAndGuidingActivitiesSection = {
    researchActivities: data?.researchActivities?.map((item) => ({ ...item })),
    guidingActivities: data?.guidingActivities?.map((item) => ({ ...item })),
  };

  const scientificWorksSection = { ...data?.scientificWorks };

  const { awards, patents, usefulSolutions, transferredSolutions, ...otherInfoSection } = {
    ...data?.others,
  };

  const awardsSection = { awards, patents, usefulSolutions, transferredSolutions };

  return {
    generalInfoSection,
    researchAndGuidingActivitiesSection,
    scientificWorksSection,
    awardsSection,
    otherInfoSection,
  };
};

export const modifyScientificProfileHomeData = (
  general,
  academicHistory,
  workHistory,
  researchActivities,
  guidingActivities,
  scientificWorks,
  others
) => {
  const generalInfoSection = {
    ...general,
    academicHistory: { ...academicHistory },
    workHistory: workHistory?.map((item) => ({ ...item })),
  };

  const researchAndGuidingActivitiesSection = {
    researchActivities: researchActivities?.map((item) => ({ ...item })),
    guidingActivities: guidingActivities?.map((item) => ({ ...item })),
  };

  const scientificWorksSection = { ...scientificWorks };

  const { awards, patents, usefulSolutions, transferredSolutions, ...otherInfoSection } = {
    ...others,
  };

  const awardsSection = { awards, patents, usefulSolutions, transferredSolutions };

  return {
    generalInfoSection,
    researchAndGuidingActivitiesSection,
    scientificWorksSection,
    awardsSection,
    otherInfoSection,
  };
};

export const modifyReviewExpenditure = (reviewExpenditure, expenditureTypes) => {
  if (!(reviewExpenditure && expenditureTypes)) return [];
  let temp = [];
  if (typeof reviewExpenditure === 'string') {
    temp = JSON.parse(reviewExpenditure);
  }
  const result = temp.map(({ expenditureTypeId, ...rest }) => {
    const expenditureType = expenditureTypes.find((e) => e.id === expenditureTypeId);
    if (expenditureType) {
      return { ...rest, expenditureType };
    }
    return { ...rest, expenditureType: null };
  });

  return result;
};

export const getOneReviewProjectProposalCouncilMembers = (
  projectProposalCouncilMembers,
  projectProposalId
) => {
  if (projectProposalCouncilMembers?.length) {
    const result = projectProposalCouncilMembers.filter(
      (row) => row.projectProposalId === projectProposalId
    );
    return result;
  }

  return [];
};

// for view detai project proposal when member review in council
export const modifyProjectProposalDefenseGeneralData = (data) => {
  if (!data) return null;

  const researchFields = data.projectProposalResearchFields.map((e) => ({
    priority: e?.priority,
    researchFieldId: e.researchField.id,
    researchField: e.researchField,
    researchLine: e.researchLine,
  }));

  const modifiedResearchFields = [];
  researchFields.forEach((e) => {
    modifiedResearchFields[e.priority - 1] = e;
  });

  const researchExpense = {
    totalExpenditure: data.totalExpenditure,
    nationalUniversityTotalExpenditure: data.nationalUniversityTotalExpenditure,
    nationalUniversityAllocatedExpenditure: data.nationalUniversityAllocatedExpenditure,
    nationalUniversityNonallocatedExpenditure: data.nationalUniversityNonallocatedExpenditure,
    fundingExpenditure: data.fundingExpenditure,
    fundingExpenditureSelf: data.fundingExpenditureSelf,
    fundingExpenditureOther: data.fundingExpenditureOther,
    otherSponsorOrganization: data.otherSponsorOrganization,
  };
  const owner = data.projectProposalContacts.find(
    (e) => PROJECT_ROLES.OWNER === e.projectRole.code
  );

  const modifiedOwner = {
    ...owner,
    workplace: data?.workplace ?? '',
    dob: owner?.dob ? new Date(owner?.dob) : null,
    dateOfIssue: owner?.dateOfIssue ? new Date(owner?.dateOfIssue) : null,
    researchLines: owner?.researchLines ?? [],
    scientificProfileUrl: owner?.contact?.user?.scientificProfileUrl,
  };

  const modifiedLead = {
    ...data.projectProposalOrganizations.find((e) => e.type === 'LEAD'),
  };
  if (modifiedLead?.englishName === 'Other') {
    modifiedLead.specificName = modifiedLead.name;
  }

  const partners = data.projectProposalOrganizations
    .filter((e) => e.type === 'PARTNER')
    ?.map((e) => ({
      ...e,
      specificName: e?.englishName === 'Other' ? e?.name : '',
      file: e?.partnerConfirmationUrl ? 'fileUploaded' : null,
    }));

  const scientificSecretaries = data.projectProposalContacts
    .filter((e) => PROJECT_ROLES.SECRETARY === e.projectRole.code)
    ?.map((e) => ({
      contact: e.contact,
      researchLines: e?.researchLines ?? [],
      assignedTask: e.assignedTask,
      isAuthorized: e.isAuthorized,
      organizationId: e.organization.id,
      projectRoleId: e.projectRole.id,
      fullname: e?.fullname,
      academicRank: e?.academicRank,
      academicDegree: e?.academicDegree,
      email: e?.email,
      specificOrganizationName:
        e?.organization?.englishName === 'Other' ? e?.specificOrganizationName : null,
    }));

  const mainParticipants = data.projectProposalContacts
    .filter((e) => PROJECT_ROLES.MAIN_PARTICIPANT === e.projectRole.code)
    ?.map((e) => ({
      contact: e.contact,
      researchLines: e?.researchLines ?? [],
      assignedTask: e.assignedTask,
      isAuthorized: e.isAuthorized,
      organizationId: e.organization.id,
      projectRoleId: e.projectRole.id,
      fullname: e?.fullname,
      academicRank: e?.academicRank,
      academicDegree: e?.academicDegree,
      email: e?.email,
      specificOrganizationName:
        e?.organization?.englishName === 'Other' ? e?.specificOrganizationName : null,
    }));
  const participants = data.projectProposalContacts
    .filter((e) => PROJECT_ROLES.PARTICIPANT === e.projectRole.code)
    ?.map((e) => ({
      contact: e.contact,
      researchLines: e?.researchLines ?? [],
      assignedTask: e.assignedTask,
      isAuthorized: e.isAuthorized,
      organizationId: e.organization.id,
      projectRoleId: e.projectRole.id,
      fullname: e?.fullname,
      academicRank: e?.academicRank,
      academicDegree: e?.academicDegree,
      email: e?.email,
      specificOrganizationName:
        e?.organization?.englishName === 'Other' ? e?.specificOrganizationName : null,
    }));
  const technicians = data.projectProposalContacts
    .filter((e) => PROJECT_ROLES.TECHNICIAN === e.projectRole.code)
    ?.map((e) => ({
      contact: e.contact,
      researchLines: e?.researchLines ?? [],
      assignedTask: e.assignedTask,
      isAuthorized: e.isAuthorized,
      organizationId: e.organization.id,
      projectRoleId: e.projectRole.id,
      fullname: e?.fullname,
      academicRank: e?.academicRank,
      academicDegree: e?.academicDegree,
      email: e?.email,
      specificOrganizationName:
        e?.organization?.englishName === 'Other' ? e?.specificOrganizationName : null,
    }));

  const domesticExperts = data.projectProposalContacts
    .filter((e) => PROJECT_ROLES.DOMESTIC_EXPERT === e.projectRole.code)
    ?.map((e) => ({
      contact: e.contact,
      researchLines: e?.researchLines ?? [],
      assignedTask: e.assignedTask,
      isAuthorized: e.isAuthorized,
      organizationId: e.organization.id,
      projectRoleId: e.projectRole.id,
      fullname: e?.fullname,
      academicRank: e?.academicRank,
      academicDegree: e?.academicDegree,
      email: e?.email,
      specificOrganizationName:
        e?.organization?.englishName === 'Other' ? e?.specificOrganizationName : null,
    }));
  const internationalExperts = data.projectProposalContacts
    .filter((e) => PROJECT_ROLES.INTERNATIONAL_EXPERT === e.projectRole.code)
    ?.map((e) => ({
      contact: e.contact,
      researchLines: e?.researchLines ?? [],
      assignedTask: e.assignedTask,
      isAuthorized: e.isAuthorized,
      organizationId: e.organization.id,
      projectRoleId: e.projectRole.id,
      fullname: e?.fullname,
      academicRank: e?.academicRank,
      academicDegree: e?.academicDegree,
      email: e?.email,
      specificOrganizationName:
        e?.organization?.englishName === 'Other' ? e?.specificOrganizationName : null,
    }));

  return {
    ...data,
    projectProposalResearchFields: modifiedResearchFields,
    researchTypesId: data.researchType.id,
    researchExpense,
    owner: modifiedOwner,
    ownerContactId: modifiedOwner?.contact?.id,
    lead: modifiedLead,
    partners,
    scientificSecretaries,
    mainParticipants,
    participants,
    technicians,
    domesticExperts,
    internationalExperts,
  };
};

export const modifyProjectProposalDefenseDocuments = (data) => {
  if (!data) return null;

  return {
    researchDescriptionFileUrl: data?.researchDescriptionFileUrl,
    expenditureDetailFileUrl: data?.expenditureFileUrl,
    otherProofsAndAppendicesFiles: data?.researchDescriptionProofFiles,
  };
};

export const modifyProjectProposalReviseLogReviewFiles = (
  reviseLog,
  projectProposalCouncilMembers,
  projectVersions
) => {
  let reviewFiles = null;
  if (reviseLog?.type === 'REQUEST_EXPLANATION_SPECIALIZATION') {
    reviewFiles = projectProposalCouncilMembers
      ?.filter((ppCouncilMember) => !!ppCouncilMember?.specializeReview)
      ?.map((ppCouncilMember, index) => ({
        key: `specialization-${ppCouncilMember?.id}`,
        projectProposalId: projectVersions?.find((item) => item?.version === 2)?.id,
        councilId: ppCouncilMember?.councilMember?.councilId,
        councilMemberId: ppCouncilMember?.councilMemberId,
        reviewer: index + 1,
        reviewFileName: `Phiếu nhận xét - đánh giá Thẩm định chuyên môn ${index + 1}`,
      }));
  } else if (reviseLog?.type === 'REQUEST_EXPLANATION_FINANCE') {
    reviewFiles = projectProposalCouncilMembers
      ?.filter((ppCouncilMember) => !!ppCouncilMember?.financialReview)
      ?.map((ppCouncilMember, index) => ({
        key: `finance-${ppCouncilMember?.id}`,
        projectProposalId: projectVersions?.find((item) => item?.version === 3)?.id,
        councilId: ppCouncilMember?.councilMember?.councilId,
        councilMemberId: ppCouncilMember?.councilMemberId,
        reviewer: index + 1,
        reviewFileName: `Phiếu nhận xét - đánh giá Thẩm định kinh phí ${index + 1}`,
      }));
  }
  return reviewFiles;
};

export const getAcademicRanks = (t) => [
  { value: 'professor', name: t('academicRank.professor') },
  { value: 'associateProfessor', name: t('academicRank.associateProfessor') },
];

export const getAcademicDegrees = (t) => [
  { value: 'doctorate', name: t('academicDegree.doctorate') },
  { value: 'master', name: t('academicDegree.master') },
  { value: 'bachelor', name: t('academicDegree.bachelor') },
  { value: 'university', name: t('academicDegree.university') },
  { value: 'associateDiploma', name: t('academicDegree.associateDiploma') },
  { value: 'highSchoolDiploma', name: t('academicDegree.highSchoolDiploma') },
];

export const getGenders = (t) => [
  { value: 'male', name: t('gender.male') },
  { value: 'female', name: t('gender.female') },
];

export const getProjectTypes = (t) => [
  { value: 'A', name: t('projectTypes.A') },
  { value: 'B', name: t('projectTypes.B') },
  { value: 'C', name: t('projectTypes.C') },
  { value: 'scienceAndTechnologyProject', name: t('projectTypes.scienceAndTechnologyProject') },
  { value: 'strongResearchTeam', name: t('projectTypes.strongResearchTeam') },
  {
    value: 'regularTasksAccordingToFunction',
    name: t('projectTypes.regularTasksAccordingToFunction'),
  },
  { value: 'maintainProject', name: t('projectTypes.maintainProject') },
  { value: 'CQG', name: t('projectTypes.CQG') },
];

export const getYearProposed = () => {
  const years = [];
  const currentYear = new Date().getFullYear();
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i <= 5; i++) {
    years.push({ value: (currentYear - i).toString(), name: (currentYear - i).toString() });
  }
  return years;
};

export const setExpenditureTableOfSummaryReport = (summaryReportExpenditure, name, type) => {
  const handler = summaryReportExpenditure?.filter(
    (item) => item?.summaryReportExpenditureType?.englishName === name
  );
  if (type === 'notes') {
    return handler?.[0]?.notes ?? null;
  }
  if (type === 'expenditure') {
    return handler?.[0]?.expenditure ?? null;
  }
  return null;
};

export const getExpenditureForExpenditureTableOfSummaryReport = (
  summaryReportExpenditureType,
  data
) => {
  // if (!data) {
  //   return data;
  // }
  const vnuExpenditureType = summaryReportExpenditureType.filter(
    (item) => item.englishName === 'Funding for the project is allocated by VNU'
  );
  const allocatedExpenditureType = summaryReportExpenditureType.filter(
    (item) => item.englishName === 'Allocated expenditure'
  );
  const usedExpenditureType = summaryReportExpenditureType.filter(
    (item) => item.englishName === 'Used expenditure'
  );
  const proposeExpenditureType = summaryReportExpenditureType.filter(
    (item) => item.englishName === 'Suggested expenditures'
  );
  const expenditureFromVNU = {
    summaryReportExpenditureTypeId: vnuExpenditureType?.[0].id,
    expenseName: vnuExpenditureType?.[0].vietnameseName,
    no: 0,
    expenditure: data.expenditureFromVNU ?? 0,
    notes: data.noteForExpenditureFromVNU,
    summaryReportExpenditureType: vnuExpenditureType?.[0],
  };

  const expenditureAllocated = {
    summaryReportExpenditureTypeId: allocatedExpenditureType?.[0].id,
    expenseName: allocatedExpenditureType?.[0].vietnameseName,
    no: 0,
    expenditure: data.expenditureAllocated ?? 0,
    notes: data.noteForExpenditureAllocated,
    summaryReportExpenditureType: allocatedExpenditureType?.[0],
  };

  const expenditureUsed = {
    summaryReportExpenditureTypeId: usedExpenditureType?.[0].id,
    expenseName: usedExpenditureType?.[0].vietnameseName,
    no: 0,
    expenditure: data.expenditureUsed ?? 0,
    notes: data.noteForExpenditureUsed,
    summaryReportExpenditureType: usedExpenditureType?.[0],
  };

  const expenditurePropose = {
    summaryReportExpenditureTypeId: proposeExpenditureType?.[0].id,
    expenseName: proposeExpenditureType?.[0].vietnameseName,
    no: 0,
    expenditure: data.expenditurePropose ?? 0,
    notes: data.noteForExpenditurePropose,
    summaryReportExpenditureType: proposeExpenditureType?.[0],
  };

  data?.summaryReportExpenditures.unshift(expenditurePropose);
  data?.summaryReportExpenditures.unshift(expenditureUsed);
  data?.summaryReportExpenditures.unshift(expenditureAllocated);
  data?.summaryReportExpenditures.unshift(expenditureFromVNU);
  return data;
};

export const getDescription = (value, summaryReportKpis) => {
  for (let i = 0; i < summaryReportKpis?.length; i += 1) {
    if (value?.data?.projectProposalResearchKpis?.[0]?.id === summaryReportKpis[i]?.researchKpiId) {
      return summaryReportKpis[i]?.description;
    }
  }
  return '';
};

export const getResearchKpiGroup = (researchKpis) => {
  const result = Object.values(
    researchKpis.reduce((acc, item) => {
      const groupId = item?.researchKpiGroup?.id;
      if (!acc[groupId]) {
        acc[groupId] = item.researchKpiGroup;
      }
      return acc;
    }, {})
  );
  return result;
};

export const formatCurrency = (number) =>
  new Intl.NumberFormat('vi-VN', { style: 'currency', currency: 'VND' }).format(number);

export function convertToUnderscore(str) {
  // Loại bỏ dấu tiếng Việt và chuyển thành chữ thường
  const normalizedStr = str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');

  // Thay thế khoảng trắng bằng dấu gạch dưới
  const underscoredStr = normalizedStr.replace(/\s+/g, '_');

  return underscoredStr;
}

export const formatFileNameProduct = (fileName, projectCode, type, order) => {
  const fileNameSplit = fileName.split('.');
  const typeFile = fileNameSplit[fileNameSplit.length - 1];
  let result;
  switch (type) {
    case 'SPC':
      result = `${projectCode}-SanPhamCung-MinhChung-${order}.${typeFile}`;
      break;
    case 'SPM':
      result = `${projectCode}-SanPhamMem-MinhChung-${order}.${typeFile}`;
      break;
    case 'SHTT':
      result = `${projectCode}-SoHuuTriTue-MinhChung-${order}.${typeFile}`;
      break;
    case 'SPDT':
      result = `${projectCode}-SanPhamDaoTao-MinhChung-${order}.${typeFile}`;
      break;
    case 'CGCN':
      result = `${projectCode}-MinhChung-${order}.${typeFile}`;
      break;
    case 'PL':
      result = `${projectCode}-PhuLuc-${order}.${typeFile}`;
      break;
    default:
      break;
  }
  return result;
};

// Check: ResearchFields: social science (code in db: science), economic&law, manage
export const isValidResearchFieldsForKpi = (projectProposalResearchFields, researchFields) =>
  projectProposalResearchFields.some((ppResearchField) =>
    researchFields.some(
      (researchField) =>
        researchField.id === ppResearchField.researchFieldId &&
        RESEARCH_FIELDS_WITH_ADDITIONAL_FUNDING_FOR_KPI.includes(researchField.code)
    )
  );

// if (K1, K2, K3, K4) + ResearchFields: social science (code in db: science), economic&law, manage
// => Each K is added: quantity * 50 (million VNĐ)
export const calculateAdditionalProposedExpenditureByKpis = (
  projectProposalResearchFields,
  projectProposalResearchKpis,
  researchFields,
  researchKpis
) => {
  let additionalProposedExpenditure = 0;

  if (isValidResearchFieldsForKpi(projectProposalResearchFields, researchFields)) {
    researchKpis.forEach((researchKpi) => {
      if (
        projectProposalResearchKpis[researchKpi.id]?.quantity &&
        (researchKpi.researchKpiGroup.name === 'K1' ||
          researchKpi.researchKpiGroup.name === 'K2' ||
          researchKpi.researchKpiGroup.name === 'K3' ||
          researchKpi.researchKpiGroup.name === 'K4')
      ) {
        additionalProposedExpenditure += projectProposalResearchKpis[researchKpi.id].quantity * 50;
      }
    });
  }
  return additionalProposedExpenditure;
};

export const getDate = (rawDate) => {
  // Parse the input UTC timestamp
  const date = new Date(rawDate);

  // Get the time in milliseconds since January 1, 1970, 00:00:00 UTC
  const utcTime = date.getTime();

  // Offset for Vietnam Time (ICT) which is +7 hours (25200000 milliseconds)
  const vietnamOffset = 7 * 60 * 60 * 1000;

  // Calculate the new time in milliseconds
  const vietnamTime = new Date(utcTime + vietnamOffset);

  // Extract hours, minutes, and seconds
  const hours = String(vietnamTime.getUTCHours()).padStart(2, '0');
  const minutes = String(vietnamTime.getUTCMinutes()).padStart(2, '0');
  const seconds = String(vietnamTime.getUTCSeconds()).padStart(2, '0');

  // Extract day, month, and year
  const day = String(vietnamTime.getUTCDate()).padStart(2, '0');
  const month = String(vietnamTime.getUTCMonth() + 1).padStart(2, '0'); // Months are zero-based
  const year = vietnamTime.getUTCFullYear();

  // Format the output string
  return `${hours}:${minutes}:${seconds}, ${day}-${month}-${year}`;
};

export const isAuthorized = (userId, projectProposalContacts) =>
  projectProposalContacts.findIndex(
    (ppContact) => ppContact.contact.userId === userId && ppContact.isAuthorized
  ) !== -1;

export const formatDate = (date) =>
  new Date(date).toLocaleDateString('en-GB', { timeZone: TIMEZONE_VN });

export const timeAgo = (date) => {
  const now = moment();

  const duration = moment.duration(now.diff(date));
  const hours = duration.asHours();
  const days = duration.asDays();
  const months = duration.asMonths();
  const years = duration.asYears();
  if (hours < 1) return 'Vừa xong';
  if (hours < 24) return `${Math.floor(hours)} giờ trước`;
  if (days < 30) return `${Math.floor(days)} ngày trước`;
  if (months < 12) return `${Math.floor(months)} tháng trước`;
  return `${Math.floor(years)} năm trước`;
};

export const getEnableProductDetailPropList = (_kpiGroupId, _kpiId, researchKpiGroups) => {
  const result = [];
  if (!_kpiGroupId || !_kpiId || !researchKpiGroups) return result;
  // Helper function to check if a KPI is a book
  function isBook(kpiName) {
    return kpiName.toLowerCase().includes('sách');
  }

  // Find the relevant K group
  const kpiGroup = researchKpiGroups.find((kg) => kg.id === _kpiGroupId);
  if (!kpiGroup) return result;

  // Find the specific KPI
  const kpi = kpiGroup.researchKpis.find((k) => k.id === _kpiId);

  const productDetail = PRODUCT_DETAIL_FIELD;

  if (['K11', 'K12'].includes(kpiGroup.name)) {
    result.push(productDetail.title, productDetail.object, productDetail.note);
  } else if (['K7', 'K8', 'K9'].includes(kpiGroup.name)) {
    result.push(
      productDetail.title,
      productDetail.author,
      productDetail.publishedYear,
      productDetail.patentCode,
      productDetail.note
    );
  } else if (kpiGroup.name === 'K10') {
    // dùng trong trường hợp K10 ở SPC, SPM
    result.push(
      productDetail.title,
      productDetail.publishedYear,
      productDetail.patentCode,
      productDetail.note
    );
  } else if (isBook(kpi.name)) {
    result.push(
      productDetail.title,
      productDetail.author,
      productDetail.publishedYear,
      productDetail.ISSN_ISBN,
      productDetail.note
    );
  } else {
    result.push(
      productDetail.title,
      productDetail.author,
      productDetail.doi,
      productDetail.publishedYear,
      productDetail.conference,
      productDetail.ISSN_ISBN,
      productDetail.rank,
      productDetail.note
    );
  }
  return result;
};

export const isEnableProductDetailProp = (field, enableFields) => {
  if (!field || !enableFields) return false;
  return enableFields.includes(field);
};

export const filterReasearchKpiGroups = (rkGroups, filter) => {
  let filteredResearchKpiGroups = [];
  if (!rkGroups || !filter) {
    return filteredResearchKpiGroups;
  }
  filteredResearchKpiGroups = rkGroups.filter((item) => filter.includes(item.name));
  return filteredResearchKpiGroups;
};

export const getProductDetail = (t, productDetail) => {
  const result = [];
  Object.keys(productDetail).forEach((key) => {
    result.push(
      `<div class="ml-2">- ${t(
        `submitDocument.product.productDetail.${PRODUCT_DETAIL_FIELD[key]}`
      )}: ${productDetail[key]} </div>`
    );
  });
  return result.join(' ');
};

export const paginationDetailString = (t, options, paginationData, pagination) =>
  `${t('pagination.row')} ${
    paginationData.totalRecords !== options.first + 1
      ? `${t('pagination.from')} ${options.first + 1} ${t('pagination.to')} ${
          paginationData.totalRecords < options.first + pagination.rowsPerPage
            ? paginationData.totalRecords
            : options.first + pagination.rowsPerPage
        }`
      : `${options.first + 1}`
  } ${t('pagination.perTotal')} ${paginationData.totalRecords}`;

export const getMonth = (date) => {
  const month = new Date(date).getMonth();
  return month + 1;
};

export const getYear = (date) => {
  const year = new Date(date).getFullYear();
  return year;
};

export const autoDownloadFile = (res) => {
  if (res?.data) {
    const file = new Blob([res.data]);
    const filename = res.headers['content-disposition']?.split('filename=')?.[1];
    const link = document.createElement('a');
    link.href = URL.createObjectURL(file);
    link.download = filename;
    link.click();
  }
};
