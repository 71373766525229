/* eslint-disable react/jsx-curly-newline */
import './style.scss';

import { Fieldset } from 'primereact/fieldset';
import PropTypes from 'prop-types';
import { Button } from 'primereact/button';
import { useTranslation } from 'react-i18next';
import { useEffect, useMemo, useState } from 'react';
import { getAllResearchKpiGroups, getAllResearchKpis } from 'apis/projectProposals.api';
import { useQuery, useMutation } from 'react-query';
import { formatFileName, setExpenditureTableOfSummaryReport } from 'utils/func';

import { downloadFileReport, getSummaryReport } from 'apis/summaryReport';
import { useParams, Link } from 'react-router-dom';

import { getProjectCode } from 'apis/project.api';
import CustomFileInput from 'components/FormControl/CustomFileInput';
import Loading from 'components/Loading';
import GeneralInfo from '../../ReportFormR05/GeneralInfo';
import ExpenditureTable from '../../ReportFormR05/ExpenditureTable';
import Review from '../../ReportFormR05/Review';
import Product from '../../Product';
import ContentCompletedTable from '../../ReportFormR05/ContentCompletedTable';
import ContentNotCompletedTable from '../../ReportFormR05/ContentNotCompletedTable';
import ReviewForm from '../../ReviewForm';
import WorkshopTable from '../../ReportFormR05/WorkshopTable';
import ParticipantsTable from '../../ReportFormR05/ParticipantsTable';
import UploadMultipleFile from '../../ReportFormR05/UploadMultipleFile';

export default function SummaryReportDetailForm({
  isAcceptanceReport,
  control,
  reset,
  setValue,
  getValues,
  errors,
  tab,
  setTab,
}) {
  const { t } = useTranslation();
  const { projectId, summaryReportId } = useParams();

  const [summaryReportAppendixeArray, setSummaryReportAppendixeArray] = useState([]);
  const [resetAppendixFiles, setResetAppendixFiles] = useState(false);

  const { mutate: mutateDownloadFile, isLoading: isDownloadFileLoading } =
    useMutation(downloadFileReport);

  const { data: _projectCode } = useQuery({
    queryKey: () => ['projectCode'],
    queryFn: () => getProjectCode(projectId),
  });

  const projectCode = useMemo(() => _projectCode?.data?.projectCode ?? '', [_projectCode]);

  const { data: _data } = useQuery({
    queryKey: ['researchKpis'],
    queryFn: getAllResearchKpis,
  });
  const researchKpis = useMemo(() => _data?.data ?? [], [_data]);
  const { data: researchKpiGroupsData } = useQuery({
    queryKey: ['researchKpiGroups'],
    queryFn: getAllResearchKpiGroups,
  });
  const researchKpiGroups = useMemo(
    () => researchKpiGroupsData?.data ?? [],
    [researchKpiGroupsData]
  );

  const { data: _summaryReport } = useQuery(
    ['midtermSummaryReport', summaryReportId],
    () => getSummaryReport(summaryReportId),
    {
      enabled: summaryReportId !== null,
    }
  );

  const summaryReport = useMemo(() => _summaryReport?.data, [_summaryReport]);
  // # End region data

  // # Region Event

  const handleDownloadFile = (filename, fileNameFormat = '') => {
    mutateDownloadFile(
      {
        summaryReportId,
        filename,
      },
      {
        onSuccess: async (res) => {
          if (res?.data) {
            const file = new Blob([res.data]);

            const link = document.createElement('a');
            link.href = URL.createObjectURL(file);
            link.download = fileNameFormat || filename;

            link.click();
          }
        },
      }
    );
  };

  // # End region event

  useEffect(() => {
    getValues('expenditureFile');
    const data = summaryReport?.summaryReport;
    if (data) {
      // only acceptance
      setSummaryReportAppendixeArray(summaryReport?.summaryReportAppendixes);

      // un-file

      setValue('summaryReportFinishedTasks', data?.summaryReportFinishedTasks ?? []);
      setValue('summaryReportUnfinishedTasks', data?.summaryReportUnfinishedTasks ?? []);
      setValue('summaryReportConferences', data?.summaryReportConferences ?? []);
      setValue('summaryReportParticipants', data?.summaryReportParticipants ?? []);

      for (let i = 0; i < data?.summaryReportParticipants.length; i += 1) {
        setValue(
          `summaryReportParticipants.${i}.joiningDate`,
          new Date(data?.summaryReportParticipants[i]?.joiningDate)
        );
      }
      for (let i = 0; i < data?.summaryReportConferences.length; i += 1) {
        setValue(
          `summaryReportConferences.${i}.organizedDate`,
          new Date(data?.summaryReportConferences[i]?.organizedDate)
        );
      }

      setValue(
        'researchResult.softProducts',
        summaryReport?.summaryReportProducts?.scientificPublications?.softProducts
      );
      setValue(
        'researchResult.hardProducts',
        summaryReport?.summaryReportProducts?.scientificPublications?.hardProducts
      );
      setValue(
        'researchResult.intellectualProperty',
        summaryReport?.summaryReportProducts?.intellectualPropertys
      );
      setValue(
        'researchResult.trainingResult',
        summaryReport?.summaryReportProducts?.trainingResults
      );
      setValue('researchResult.transferTechnology', data?.summaryReportTransferredTechnologies);
      for (let i = 0; i < data?.summaryReportTransferredTechnologies.length; i += 1) {
        setValue(
          `researchResult.transferTechnology.${i}.contractedDate`,
          new Date(data?.summaryReportTransferredTechnologies[i]?.contractedDate)
        );
      }
      const summaryReportExpenditure =
        data?.summaryReportExpenditures?.filter((item) => item?.no === 0) ?? [];
      const subSummaryReportExpenditures =
        data?.summaryReportExpenditures?.filter((item) => item?.no !== 0) ?? [];
      setValue('summaryReportExpenditures', subSummaryReportExpenditures ?? []);
      setValue(
        'expenditureFromVNU',
        setExpenditureTableOfSummaryReport(
          summaryReportExpenditure,
          'Funding for the project is allocated by VNU',
          'expenditure'
        )
      );
      setValue(
        'expenditureAllocated',
        setExpenditureTableOfSummaryReport(
          summaryReportExpenditure,
          'Allocated expenditure',
          'expenditure'
        )
      );
      setValue(
        'expenditureUsed',
        setExpenditureTableOfSummaryReport(
          summaryReportExpenditure,
          'Used expenditure',
          'expenditure'
        )
      );
      setValue(
        'expenditurePropose',
        setExpenditureTableOfSummaryReport(
          summaryReportExpenditure,
          'Suggested expenditures',
          'expenditure'
        )
      );
      setValue(
        'noteForExpenditureFromVNU',
        setExpenditureTableOfSummaryReport(
          summaryReportExpenditure,
          'Funding for the project is allocated by VNU',
          'notes'
        )
      );
      setValue(
        'noteForExpenditureAllocated',
        setExpenditureTableOfSummaryReport(
          summaryReportExpenditure,
          'Allocated expenditure',
          'notes'
        )
      );
      setValue(
        'noteForExpenditureUsed',
        setExpenditureTableOfSummaryReport(summaryReportExpenditure, 'Used expenditure', 'notes')
      );
      setValue(
        'noteForExpenditurePropose',
        setExpenditureTableOfSummaryReport(
          summaryReportExpenditure,
          'Suggested expenditures',
          'notes'
        )
      );

      setValue('suggestion', data?.suggestion ?? '');
      setValue('contentReview', data?.contentReview ?? '');
      setValue('productReview', data?.productReview ?? '');
      setValue('processReview', data?.processReview ?? '');
    }
  }, [summaryReport]);

  return (
    <div className="project-proposal-form m-0 bg-white p-5 p-fluid">
      <form autoComplete="off" className="form-midterm-container">
        <Fieldset className="border-none p-0 p-fluid">
          {/* Tab 1 */}
          <div className={tab !== 0 ? 'hidden' : ''}>
            <GeneralInfo />
          </div>
          {/* Tab 2 */}
          <div className={tab !== 1 ? 'hidden' : ''}>
            <div>
              <h4 className="my-1">{t('r05.b.b1.title')}</h4>
              <h6 className="my-2 font-bold">{t('r05.b.b1.no1.title')}</h6>
              <ContentCompletedTable control={control} errors={errors} disabled />
              <h6 className="my-2">{t('r05.b.b1.no2.title')}</h6>
              <ContentNotCompletedTable control={control} errors={errors} disabled />
            </div>
            <h4 className="my-1">{t('r05.b.b2.title')}</h4>
            <Product
              projectCode={projectCode}
              researchKpis={researchKpis}
              researchKpiGroups={researchKpiGroups}
              control={control}
              errors={errors}
              disabled
              handleDownloadFile={handleDownloadFile}
            />
            <h4>{t('r05.b.b3.title')}</h4>
            <WorkshopTable control={control} errors={errors} disabled />
            <p className="mt-4">{t('r05.b.b3.no2.description')}</p>
            <ParticipantsTable control={control} errors={errors} disabled />
          </div>
          {/* Tab 3 */}
          <div className={tab !== 2 ? 'hidden' : ''}>
            <h4 className="my-1">{t('r05.b.b4.title')}</h4>
            <ExpenditureTable control={control} errors={errors} disabled />
            <h5 className="mb-0">
              {t('r05.b.b4.expenditureProof')}
              <span className="font-bold text-red-500"> *</span>
            </h5>
            <CustomFileInput
              name="expenditureFile"
              disabled
              defaultNameFile={`${projectCode}-MinhChungKinhPhi-${summaryReport?.summaryReport?.version}.xlsx`}
              control={control}
              errors={errors}
              accept=".xlsx"
            />
            {getValues('expenditureFileUrl') && (
              <span
                className="p-button p-button-text p-button-sm mt-1 p-0"
                onClick={() => handleDownloadFile(getValues('expenditureFileUrl'))}
              >
                <i className="pi pi-file-pdf text-green-500 text-xl" />{' '}
                <span className="text-blue-500 underline font-italic text-base">
                  {formatFileName(getValues('expenditureFileUrl'))}
                </span>
              </span>
            )}
          </div>
          {/* Tab 4 */}
          <div className={tab !== 3 ? 'hidden' : ''}>
            <Review control={control} errors={errors} readOnly />
            {isAcceptanceReport && (
              <>
                <div className="mt-4">
                  <h5 className="m-0">
                    {t('r05.uploadSummaryReport')} <span className="text-red-500">*</span>{' '}
                    <a
                      className="text-blue-500 font-italic text-lg font-medium"
                      href="/files/R08-BAO-CAO-TONG-KET.docx"
                      download
                    >
                      {t('formLayout.action.download')} {t('r05.fileTemplate')}{' '}
                      <i className="pi pi-download text-blue-500" />
                    </a>
                  </h5>
                  <CustomFileInput
                    name="summaryReportFile"
                    defaultNameFile={`${projectCode}-BaoCaoTongKet.pdf`}
                    control={control}
                    errors={errors}
                    accept=".pdf"
                    acceptFileMessage="Chỉ chấp nhận file PDF"
                    acceptOnly="pdfOnly"
                  />
                </div>
                {summaryReport?.summaryReport?.summaryReportFileUrl && (
                  <span
                    className="p-button p-button-text p-button-sm mt-1 p-0"
                    onClick={() =>
                      handleDownloadFile(
                        summaryReport?.summaryReport?.summaryReportFileUrl,
                        formatFileName(summaryReport?.summaryReport?.summaryReportFileUrl)
                      )
                    }
                  >
                    <i className="pi pi-file-pdf text-red-500 text-xl" />{' '}
                    <span className="text-blue-500 underline font-italic text-base">
                      {formatFileName(summaryReport?.summaryReport?.summaryReportFileUrl)}
                    </span>
                  </span>
                )}
                <UploadMultipleFile
                  control={control}
                  errors={errors}
                  summaryReportAppendixeArray={summaryReportAppendixeArray}
                  handleDownloadFile={handleDownloadFile}
                  disabled
                  setSummaryReportAppendixeArray={setSummaryReportAppendixeArray}
                  resetAppendixFiles={resetAppendixFiles}
                  setResetAppendixFiles={setResetAppendixFiles}
                  projectCode={summaryReport?.summaryReport?.projectCode}
                  reset={reset}
                />
              </>
            )}
          </div>
        </Fieldset>
        {/* Tab 5 */}
        {tab !== 4 && (
          <div className="flex justify-content-end mt-2">
            <Button
              type="button"
              className="p-button-next btn-control"
              onClick={() => {
                setTab((prev) => prev + 1);
              }}
            >
              {t('formLayout.next')}
            </Button>
          </div>
        )}
        {isDownloadFileLoading && <Loading />}
      </form>
      <div className={tab !== 4 ? 'hidden' : 'ml-4'}>
        <ReviewForm
          summaryReport={summaryReport}
          handleDownloadFile={handleDownloadFile}
          control={control}
          errors={errors}
        />
        <div className="flex justify-content-end mt-6">
          <Link to="/project">
            <Button type="button" className="btn-submit  font-semibold mr-3" severity="danger">
              {t('submitDocument.back')}
            </Button>
          </Link>
        </div>
      </div>
    </div>
  );
}

SummaryReportDetailForm.propTypes = {
  isAcceptanceReport: PropTypes.bool,
  setValue: PropTypes.func.isRequired,
  getValues: PropTypes.func.isRequired,
  reset: PropTypes.func.isRequired,
  control: PropTypes.shape({}).isRequired,
  errors: PropTypes.shape({}).isRequired,
  tab: PropTypes.number,
  setTab: PropTypes.func,
  dirtyFields: PropTypes.shape({}).isRequired,
};

SummaryReportDetailForm.defaultProps = {
  isAcceptanceReport: false,
  tab: 0,
  setTab: () => null,
};
