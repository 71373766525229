import { Dialog } from 'primereact/dialog';
import { Message } from 'primereact/message';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { TextareaInput } from 'components/FormControl';
import PropTypes from 'prop-types';
import { Button } from 'primereact/button';
import { updateSummaryReportRevise } from 'apis/summaryReport';
import { useMutation } from 'react-query';
import { useParams } from 'react-router-dom';
import { toast } from 'layout';
import { TOAST } from 'constant';
import { yupResolver } from '@hookform/resolvers/yup';
import { completedReviseSummaryReportSchema } from 'features/Project/validation';

function CompleteReviseModal({
  isCompleteReviseModalOpen,
  setIsCompleteReviseModalOpen,
  id,
  refetch,
}) {
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(completedReviseSummaryReportSchema),
  });
  const { t } = useTranslation();

  const { summaryReportId } = useParams();

  const { mutate } = useMutation(updateSummaryReportRevise);

  const onSubmit = (data) => {
    mutate(
      { summaryReportId, id, body: data },
      {
        onSuccess: () => {
          toast(TOAST.SUCCESS, t('submitDocument.toast.reviseSuccess'));
          refetch();
          setIsCompleteReviseModalOpen(false);
        },
        onError: () => {
          toast(TOAST.ERROR, t('submitDocument.toast.reviseError'));
        },
      }
    );
  };
  return (
    <Dialog
      header={<p className="text-blue-500">{t('projectProposal.confirmationTitle')}</p>}
      visible={isCompleteReviseModalOpen}
      position="center"
      style={{ width: '400px' }}
      onHide={() => setIsCompleteReviseModalOpen(false)}
      draggable={false}
    >
      <form onSubmit={handleSubmit(onSubmit)} className="p-fluid">
        <TextareaInput
          name="feedback"
          label={t('submitDocument.feedback')}
          control={control}
          errors={errors}
          row={6}
          isRequired
        />
        <Message
          className="mt-4 w-full"
          severity="warn"
          text={t('submitDocument.confirmationReviseMessage')}
        />
        <div className="flex justify-content-end gap-2 mt-4">
          <Button
            type="button"
            label={t('employee.cancel')}
            className="w-7rem"
            severity="danger"
            onClick={() => setIsCompleteReviseModalOpen(false)}
            autoFocus
          />
          <Button label={t('employee.ok')} className="w-7rem" severity="info" type="submit" />
        </div>
      </form>
    </Dialog>
  );
}

CompleteReviseModal.propTypes = {
  isCompleteReviseModalOpen: PropTypes.bool.isRequired,
  setIsCompleteReviseModalOpen: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  refetch: PropTypes.func.isRequired,
};

export default CompleteReviseModal;
