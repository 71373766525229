import { LayoutProvider } from 'layout/context/layoutcontext';
import Layout from 'layout';
import SummaryReportRequest from 'features/Project/pages/Request';

export default function SummaryReportRequestPage() {
  return (
    <LayoutProvider>
      <Layout>
        <SummaryReportRequest />
      </Layout>
    </LayoutProvider>
  );
}
